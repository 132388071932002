import React from "react"
import styled from "styled-components"
import posed from "react-pose"

import MenuLink from "./MenuLink"
import SocialIcons from "../shared/SocialIcons"
import { getFontFamily, getInverseColour } from "../../utils"
import IconChevronDown from "../../dsm/icons/files/chevron-down"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const MobileNavigation = ({
  menu,
  shape,
  isOpen,
  lightSkin,
  hoverColor,
  hasMenuItems,
  headingTypography,
  overrideColors,
}) => {
  const classes = ["main-nav mobile"]
  if (isOpen) {
    classes.push("open")
  } else classes.push("closed")

  return (
    <MobileNavWrapper
      shape={shape}
      lightSkin={lightSkin}
      className={classes.join(" ")}
      hoverColor={hoverColor}
      headingTypography={headingTypography}
      overrideColors={overrideColors}
    >
      {isOpen && (
        <div
          dangerouslySetInnerHTML={{
            __html: `<style>section > div.progress-bar,section > button.next,section > button.prev{display:none;}</style>`,
          }}
        />
      )}
      <Inner lightSkin={lightSkin}>
        {hasMenuItems && (
          <NavUL className="links" pose={isOpen ? "open" : "closed"}>
            {menu.menuItems.nodes.map((menuItem) => (
              <Item key={menuItem.id}>
                {menuItem?.childItems?.nodes?.length ? (
                  <SubItems {...menuItem} overrideColors={overrideColors} />
                ) : (
                  <MenuLink menuItem={menuItem} />
                )}
              </Item>
            ))}
          </NavUL>
        )}
        <SocialIconsWrapper pose={isOpen ? "open" : "closed"}>
          <SocialIcons color={hoverColor} inNav />
        </SocialIconsWrapper>
      </Inner>
    </MobileNavWrapper>
  )
}

const SubItems = (menuItem) => {
  const [open, setOpen] = React.useState(false)
  const items = [menuItem, ...menuItem?.childItems?.nodes]
  return (
    <SubWrapper
      className="sub"
      overrideColors={menuItem.overrideColors}
      open={open}
    >
      <div onClick={() => setOpen((p) => !p)} className="a">
        {menuItem.label} <IconChevronDown className="icon" />
      </div>
      {open && items.map((i) => <MenuLink key={i.id} menuItem={i} stop />)}
    </SubWrapper>
  )
}
export default MobileNavigation
const SubWrapper = styled.div`
  svg {
    margin-left: 15px;
    transition: 0.2s all ease-in-out;
  }
  ${({ open }) => (open ? "svg{transform:rotate(180deg)}" : "")}
  a {
    border-left: 3px solid
      ${({ theme, lightSkin, overrideColors }) =>
        overrideColors
          ? overrideColors.color
          : lightSkin
          ? theme.colors.white[100]
          : theme.colors.midnight[400]};
  }
`

///////////////////////////////////////
// ⚡️ Animation
///////////////////////////////////////
const NavUL = posed.ul({
  open: {
    delayChildren: 500,
    staggerChildren: 80,
  },
})

const Item = posed.li({
  open: { x: 0, opacity: 1 },
  closed: { x: -100, opacity: 0 },
})

const SocialIconsWrapper = posed.div({
  open: { y: 0, opacity: 1, delay: 1200 },
  closed: { y: 200, opacity: 0 },
})

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const MobileNavWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: calc(100vh + 75px);
  z-index: 9998;
  transition: all 0.2s cubic-bezier(0.19, 0.61, 1, 0.31);
 &.closed {
    max-height: 0;
    overflow: hidden;
  }
  &.open {
    display: block;
    max-height: calc(100vh + 75px);
  }
  .social-icons {
    justify-content: center;
    padding: 20px 0;
    /* border-top: 1px solid
      ${({ theme, lightSkin }) =>
        lightSkin ? theme.colors.tusk[300] : theme.colors.midnight[100]}; */
  }
  ul {
    margin: 0;
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;
    list-style-type: none;
    /* li:last-child a {
      border-bottom: none;
    } */
    a,
    .a {
      
       padding: 20px;
      text-align: center;
      display: block;
      text-decoration: none;
       font-family: ${({ headingTypography }) =>
         getFontFamily(headingTypography.fontFamily)};
      font-weight: ${({ headingTypography }) =>
        headingTypography.h1.fontWeight};
      font-size: 1.15rem;
      transition: all 200ms ease-in-out;
      &:hover {
        /* color: ${({ hoverColor }) => getInverseColour(hoverColor)};
        background: ${({ hoverColor }) => hoverColor}; */
      }
      &[aria-current="page"] {
        /* background: ${({ hoverColor }) => `${hoverColor}20`}; */
        &:hover {
          /* background: ${({ hoverColor }) => hoverColor}; */
        }
      }
      &.cta {
        color: ${({ hoverColor }) => getInverseColour(hoverColor)};
        background-color: ${({ hoverColor }) => hoverColor};
        border: none !important;
        padding: 10px 22px;
        margin: 20px;
        border-radius: ${({ shape }) => shape};
        &:hover {
          transform: translateY(-3px);
        }
      }
    }
  }
`
const Inner = styled.div`
  border-top: 1px solid
    ${({ theme, lightSkin }) =>
      lightSkin ? theme.colors.tusk[300] : theme.colors.midnight[100]};
  margin: 74px 0 20px 0;
`
