import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import getFlexiOptionsBranding from "../../options/getFlexiOptionsBranding"
import Icon from "../icons/icon"
import { getInverseColour, getFontFamily } from "../../utils"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const Button = ({
  label = "More",
  to = "/",
  type = "primary",
  isExternal = false,
  icon = "none",
  iconLeft = "none",
  color,
  image,
}) => {
  const {
    shape,
    lightSkin,
    primaryColor,
    secondaryColor,
    headingTypography,
  } = getFlexiOptionsBranding()

  let Wrapper = PrimaryButton
  switch (type) {
    case "primary":
      Wrapper = PrimaryButton
      break
    case "secondary":
      Wrapper = SecondaryButton
      break
    case "solid":
      Wrapper = SolidButton
      break
    case "outline":
      Wrapper = OutlineButton
      break
    case "ghost":
      Wrapper = GhostButton
      break
    case "image":
      Wrapper = ImageButton
      break
    default:
      Wrapper = PrimaryButton
      break
  }

  return (
    <Wrapper
      image={image}
      shape={shape}
      lightSkin={lightSkin}
      primaryColor={primaryColor}
      secondaryColor={secondaryColor}
      specificColor={color}
      headingTypography={headingTypography}
      to={isExternal ? null : to}
      href={isExternal ? to : null}
      as={isExternal ? "a" : Link}
      rel={isExternal ? "noopener noreferrer" : null}
      target={isExternal ? "_blank" : null}
      className={`button ${type}`}
      hasicon={icon !== "none" || iconLeft !== "none" ? true : undefined}
    >
      {iconLeft !== "none" && <Icon name={iconLeft} />}
      {type !== "image" && label}
      {icon !== "none" && <Icon name={icon} />}
    </Wrapper>
  )
}
export default Button

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const ButtonBase = styled(Link)`
  padding: 15px 35px;
  display: ${({ hasicon }) => (hasicon ? "flex" : "inline-block")};
  justify-content: center;
  align-items: center;
  width: fit-content;
  margin: 0;
  text-decoration: none;
  border: none;
  text-transform: capitalize;
  transition: all 200ms ease-in-out;
  border-radius: ${({ shape }) => shape};
  font-family: ${({ headingTypography }) =>
    getFontFamily(headingTypography.fontFamily)};
  font-weight: ${({ headingTypography }) => headingTypography.h1.fontWeight};
  color: ${({ theme, lightSkin, specificColor }) =>
    specificColor
      ? specificColor
      : lightSkin
      ? theme.colors.midnight[100]
      : theme.colors.white[100]};
  svg {
    padding-left: 15px;
    #icon {
      fill: ${({ theme, lightSkin, specificColor }) =>
        specificColor
          ? specificColor
          : lightSkin
          ? theme.colors.midnight[100]
          : theme.colors.white[100]};
    }
  }
  min-width: 120px;
  text-align: center;
  &:hover {
    transform: translateY(-3px);
    border: none;
  }
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    width: calc(100% - 70px);
  }
`
const ImageButton = styled(ButtonBase)`
  background: url(${({ image }) => image.sourceUrl});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  min-height: 20px;
`

const PrimaryButton = styled(ButtonBase)`
  color: ${({ primaryColor }) => getInverseColour(primaryColor)};
  svg #icon {
    fill: ${({ primaryColor }) => getInverseColour(primaryColor)};
  }
  background: ${({ primaryColor }) => `${primaryColor}E6`};
  &:hover {
    background: ${({ primaryColor }) => `${primaryColor}`};
  }
`
const SecondaryButton = styled(ButtonBase)`
  color: ${({ secondaryColor }) => getInverseColour(secondaryColor)};
  svg #icon {
    fill: ${({ secondaryColor }) => getInverseColour(secondaryColor)};
  }
  background: ${({ secondaryColor }) => `${secondaryColor}E6`};
  &:hover {
    background: ${({ secondaryColor }) => `${secondaryColor}`};
  }
`
const SolidButton = styled(ButtonBase)`
  color: ${({ lightSkin, theme, specificColor }) =>
    specificColor
      ? getInverseColour(specificColor)
      : lightSkin
      ? theme.colors.midnight[500]
      : theme.colors.tusk[200]};
  background: ${({ lightSkin, theme, specificColor }) =>
    specificColor
      ? `${specificColor}E6`
      : lightSkin
      ? theme.colors.tusk[300]
      : theme.colors.midnight[500]};
  svg #icon {
    fill: ${({ theme, lightSkin, specificColor }) =>
      specificColor
        ? getInverseColour(specificColor)
        : lightSkin
        ? theme.colors.midnight[500]
        : theme.colors.tusk[200]};
  }
  &:hover {
    background: ${({ lightSkin, theme, specificColor }) =>
      specificColor
        ? specificColor
        : lightSkin
        ? theme.colors.tusk[400]
        : theme.colors.midnight[600]};
  }
`
const OutlineButton = styled(ButtonBase)`
  border: 2px solid
    ${({ lightSkin, theme, specificColor }) =>
      specificColor
        ? specificColor
        : lightSkin
        ? theme.colors.midnight[300]
        : theme.colors.tusk[300]};
  &:hover {
    border: 2px solid
      ${({ lightSkin, theme, specificColor }) =>
        specificColor
          ? specificColor
          : lightSkin
          ? theme.colors.midnight[200]
          : theme.colors.tusk[100]};
    background-color: ${({ lightSkin }) =>
      lightSkin ? "rgba(0, 0, 0, 0.05)" : "rgba(255, 255, 255, 0.05)"};
  }
`
const GhostButton = styled(ButtonBase)`
  padding: 15px 0;
  text-align: left;
  min-width: auto;
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    width: fit-content;
  }
`
