import React from "react"
import styled from "styled-components"

import getFlexiOptionsContent from "../../../options/getFlexiOptionsContent"
import getFlexiOptionsBranding from "../../../options/getFlexiOptionsBranding"
import Icon from "../../../dsm/icons/icon"
import { getInverseColour } from "../../../utils"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const SocialIcons = ({ color, lightSkin, inNav = false }) => {
  const { socialNetworks } = getFlexiOptionsContent()
  const { shape } = getFlexiOptionsBranding()

  return (
    <Wrapper
      inNav={inNav}
      shape={shape}
      lightSkin={lightSkin}
      className="social-icons"
      color={color}
    >
      {socialNetworks &&
        socialNetworks.map((channel) => (
          <a
            target="_blank"
            key={channel.name}
            href={channel.link}
            rel="noopener noreferrer"
          >
            <Icon name={channel.name} color={color} />
          </a>
        ))}
    </Wrapper>
  )
}
export default SocialIcons

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const Wrapper = styled.div`
  display: flex;
  .icon {
    background: ${({ inNav, color, lightSkin }) =>
      inNav
        ? `${color}E6`
        : lightSkin
        ? "rgba(255, 255, 255, 0.2)"
        : "rgba(255, 255, 255, 0.1)"};
    margin-left: 10px;
    padding: 10px;
    border-radius: ${({ shape }) => shape};
    transition: all 150ms ease-in-out;
    svg {
      opacity: ${({ inNav }) => (inNav ? "1" : "0.5")};
      #icon {
        fill: ${({ inNav, color }) =>
          inNav ? getInverseColour(color) : color};
      }
    }
    &:hover {
      svg {
        opacity: 1;
        #icon {
          fill: ${({ color }) => getInverseColour(color)};
        }
      }
      background: ${({ color }) => color};
    }
  }
`
