import React from "react"

const IconPinterest = ({ fill }) => (
  <svg
    width="15"
    height="21"
    viewBox="0 0 15 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.96875 0.773438C3.94531 0.773438 0 3.42969 0 7.76562C0 10.5 1.52344 12.0625 2.46094 12.0625C2.85156 12.0625 3.08594 11.0078 3.08594 10.6953C3.08594 10.3438 2.14844 9.5625 2.14844 8.03906C2.14844 4.91406 4.53125 2.6875 7.61719 2.6875C10.3125 2.6875 12.2656 4.21094 12.2656 6.98438C12.2656 9.05469 11.4453 12.9219 8.75 12.9219C7.77344 12.9219 6.91406 12.2188 6.91406 11.2422C6.91406 9.75781 7.96875 8.3125 7.96875 6.78906C7.96875 4.21094 4.29688 4.67969 4.29688 7.80469C4.29688 8.46875 4.375 9.17188 4.6875 9.79688C4.14062 12.1016 3.04688 15.5781 3.04688 17.9609C3.04688 18.7031 3.125 19.4062 3.20312 20.1484C3.32031 20.3047 3.28125 20.3047 3.47656 20.2266C5.42969 17.5312 5.39062 16.9844 6.25 13.4688C6.75781 14.3672 7.96875 14.875 8.98438 14.875C13.125 14.875 15 10.8125 15 7.17969C15 3.3125 11.6406 0.773438 7.96875 0.773438Z"
      fill={fill}
      id="icon"
    />
  </svg>
)

export default IconPinterest
