import React from "react"
import styled from "styled-components"
import InnerHTML from "dangerously-set-html-content"

import Icon from "../../../dsm/icons/icon"
import Inner from "../../../dsm/layout/Inner"
import MenuLink from "../../Navigation/MenuLink"
import SocialIcons from "../../shared/SocialIcons"
import { getFontFamily } from "../../../utils"
import {
  formatPhoneToLink,
  formatEmailToLink,
  formatPhoneToWhatsappLink,
} from "../../../utils"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const FooterLayoutB = ({
  menu,
  lightSkin,
  textColour,
  hoverColour,
  footerContent,
  bodyTypography,
  contactDetails,
  backgroundColour,
}) => {
  const finalTextColour = textColour || (lightSkin ? "#F5F5F5" : "#E5E5E6")

  return (
    <FooterWrapper
      lightSkin={lightSkin}
      hoverColour={hoverColour}
      bodyTypography={bodyTypography}
      finalTextColour={finalTextColour}
      backgroundColour={backgroundColour}
    >
      <Inner>
        <SocialIcons color={hoverColour} />
        <div className="top-row">
          {contactDetails.emails && (
            <div className="contact-block">
              <Icon name="paper-plane" color={`${finalTextColour}80`} />
              <span
                dangerouslySetInnerHTML={{
                  __html: formatEmailToLink(contactDetails.emails[0].email),
                }}
              />
            </div>
          )}
          <div className="numbers">
            {contactDetails.telephoneNumbers &&
              contactDetails.telephoneNumbers.map(
                ({ telephoneNumber, type }) => (
                  <div className="contact-block">
                    <Icon
                      name={type === "whatsapp" ? type : "mobile"}
                      color={`${finalTextColour}80`}
                    />
                    <span
                      dangerouslySetInnerHTML={{
                        __html: {
                          call: formatPhoneToLink,
                          whatsapp: formatPhoneToWhatsappLink,
                        }[type](telephoneNumber),
                      }}
                    />
                  </div>
                )
              )}
          </div>
        </div>
        <div>
          {menu.menuItems.nodes.map((menuItem) => (
            <MenuLink key={menuItem.id} menuItem={menuItem} />
          ))}
        </div>
        <p className="copyright">
          {footerContent.copyrightDefaultYear
            ? `© ${new Date().getFullYear()} `
            : null}
          <span
            dangerouslySetInnerHTML={{
              __html: footerContent.copyrightText,
            }}
          />
        </p>
        <div className="accreditation">
          <InnerHTML html={footerContent.accreditationText} />
        </div>
      </Inner>
    </FooterWrapper>
  )
}
export default FooterLayoutB

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const FooterWrapper = styled.footer`
  padding: 30px 0;
  color: ${({ finalTextColour }) => finalTextColour};
  background: ${({ backgroundColour, lightSkin, theme }) =>
    backgroundColour
      ? backgroundColour
      : lightSkin
      ? theme.colors.midnight[100]
      : theme.colors.midnight[400]};
  a {
    line-height: 2;
    color: ${({ finalTextColour }) => finalTextColour};
    &:hover {
      color: ${({ hoverColour }) => hoverColour};
    }
  }
  font-family: ${({ bodyTypography }) =>
    getFontFamily(bodyTypography.fontFamily)};
  font-size: 0.85rem;
  text-align: center;
  .accreditation {
    p {
      font-size: 0.7rem;
      opacity: 0.6;
      transition: all 200ms ease-in-out;
      margin: 15px 0 0 0;
    }
    a {
      text-decoration: none;
    }
    &:hover {
      opacity: 1;
      a {
        border-bottom: 1px solid ${({ hoverColour }) => hoverColour};
      }
    }
  }
  a {
    padding: 2px 3px;
    margin: 0 4px;
    text-decoration: none;
    border-bottom: 2px solid ${({ hoverColour }) => `${hoverColour}4D`};
    transition: all 200ms ease-in-out;
    &:hover {
      border-bottom: 2px solid ${({ hoverColour }) => hoverColour};
    }
  }
  .copyright {
    margin-top: 30px;
  }
  .social-icons {
    justify-content: center;
    margin: 0 20px 30px 20px;
    a {
      padding: 0;
      border: none;
    }
  }
  .top-row {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
  }
  .contact-block {
    display: grid;
    align-items: center;
    grid-template-columns: 30px 1fr;
    a {
      text-decoration: none;
      transition: all 200ms ease-in-out;
      &:hover {
        opacity: 1;
      }
    }
  }
  .numbers {
    display: flex;
    gap: 20px;
  }
`
