import React from "react"

const IconInfo = ({ fill }) => (
  <svg
    width="7"
    height="20"
    viewBox="0 0 7 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.867188 17.3438C0.361328 17.3438 0 17.7773 0 18.2109V18.7891C0 19.2949 0.361328 19.6562 0.867188 19.6562H6.07031C6.50391 19.6562 6.9375 19.2949 6.9375 18.7891V18.2109C6.9375 17.7773 6.50391 17.3438 6.07031 17.3438H5.20312V7.22656C5.20312 6.79297 4.76953 6.35938 4.33594 6.35938H0.867188C0.361328 6.35938 0 6.79297 0 7.22656V7.80469C0 8.31055 0.361328 8.67188 0.867188 8.67188H1.73438V17.3438H0.867188ZM3.46875 0C2.16797 0 1.15625 1.08398 1.15625 2.3125C1.15625 3.61328 2.16797 4.625 3.46875 4.625C4.69727 4.625 5.78125 3.61328 5.78125 2.3125C5.78125 1.08398 4.69727 0 3.46875 0Z"
      fill={fill}
      id="icon"
    />
  </svg>
)

export default IconInfo
