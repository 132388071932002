import React from "react"

const IconSpotify = ({ fill }) => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.6875 0.8125C4.33594 0.8125 0 5.1875 0 10.5C0 15.8516 4.33594 20.1875 9.6875 20.1875C15 20.1875 19.375 15.8516 19.375 10.5C19.375 5.1875 15 0.8125 9.6875 0.8125ZM13.5938 15.0703C13.4375 15.0703 13.3203 15.0312 13.2031 14.9531C10.7422 13.4688 7.92969 13.4297 5.11719 13.9766C4.96094 14.0156 4.76562 14.0938 4.64844 14.0938C4.25781 14.0938 4.02344 13.7812 4.02344 13.4688C4.02344 13.0781 4.25781 12.8828 4.57031 12.8047C7.77344 12.1016 11.0156 12.1797 13.8281 13.8203C14.0625 13.9766 14.1797 14.1328 14.1797 14.4844C14.1797 14.8359 13.9062 15.0703 13.5938 15.0703ZM14.6484 12.5312C14.4531 12.5312 14.2969 12.4141 14.1797 12.375C11.7188 10.9297 8.08594 10.3438 4.84375 11.2031C4.64844 11.2422 4.57031 11.3203 4.375 11.3203C3.98438 11.3203 3.63281 10.9688 3.63281 10.5391C3.63281 10.1484 3.82812 9.875 4.21875 9.75781C5.3125 9.44531 6.44531 9.21094 8.04688 9.21094C10.5859 9.21094 13.0469 9.83594 14.9609 11.0078C15.2734 11.1641 15.4297 11.4375 15.4297 11.75C15.3906 12.1797 15.0781 12.5312 14.6484 12.5312ZM15.8594 9.5625C15.6641 9.5625 15.5469 9.48438 15.3516 9.40625C12.5781 7.72656 7.61719 7.33594 4.375 8.23438C4.25781 8.27344 4.0625 8.35156 3.86719 8.35156C3.35938 8.35156 2.96875 7.92188 2.96875 7.41406C2.96875 6.86719 3.32031 6.59375 3.67188 6.47656C5.03906 6.08594 6.5625 5.89062 8.24219 5.89062C11.0938 5.89062 14.1016 6.47656 16.25 7.76562C16.5625 7.92188 16.7578 8.15625 16.7578 8.625C16.7578 9.17188 16.3281 9.5625 15.8594 9.5625Z"
      fill={fill}
      id="icon"
    />
  </svg>
)

export default IconSpotify
