import React from "react"

const IconCalendar = ({ fill }) => (
  <svg
    width="18"
    height="21"
    viewBox="0 0 18 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.875 3H14V0.96875C14 0.734375 13.7656 0.5 13.5312 0.5H13.2188C12.9453 0.5 12.75 0.734375 12.75 0.96875V3H5.25V0.96875C5.25 0.734375 5.01562 0.5 4.78125 0.5H4.46875C4.19531 0.5 4 0.734375 4 0.96875V3H2.125C1.07031 3 0.25 3.85938 0.25 4.875V18.625C0.25 19.6797 1.07031 20.5 2.125 20.5H15.875C16.8906 20.5 17.75 19.6797 17.75 18.625V4.875C17.75 3.85938 16.8906 3 15.875 3ZM2.125 4.25H15.875C16.1875 4.25 16.5 4.5625 16.5 4.875V6.75H1.5V4.875C1.5 4.5625 1.77344 4.25 2.125 4.25ZM15.875 19.25H2.125C1.77344 19.25 1.5 18.9766 1.5 18.625V8H16.5V18.625C16.5 18.9766 16.1875 19.25 15.875 19.25ZM6.03125 13C6.26562 13 6.5 12.8047 6.5 12.5312V10.9688C6.5 10.7344 6.26562 10.5 6.03125 10.5H4.46875C4.19531 10.5 4 10.7344 4 10.9688V12.5312C4 12.8047 4.19531 13 4.46875 13H6.03125ZM9.78125 13C10.0156 13 10.25 12.8047 10.25 12.5312V10.9688C10.25 10.7344 10.0156 10.5 9.78125 10.5H8.21875C7.94531 10.5 7.75 10.7344 7.75 10.9688V12.5312C7.75 12.8047 7.94531 13 8.21875 13H9.78125ZM13.5312 13C13.7656 13 14 12.8047 14 12.5312V10.9688C14 10.7344 13.7656 10.5 13.5312 10.5H11.9688C11.6953 10.5 11.5 10.7344 11.5 10.9688V12.5312C11.5 12.8047 11.6953 13 11.9688 13H13.5312ZM9.78125 16.75C10.0156 16.75 10.25 16.5547 10.25 16.2812V14.7188C10.25 14.4844 10.0156 14.25 9.78125 14.25H8.21875C7.94531 14.25 7.75 14.4844 7.75 14.7188V16.2812C7.75 16.5547 7.94531 16.75 8.21875 16.75H9.78125ZM6.03125 16.75C6.26562 16.75 6.5 16.5547 6.5 16.2812V14.7188C6.5 14.4844 6.26562 14.25 6.03125 14.25H4.46875C4.19531 14.25 4 14.4844 4 14.7188V16.2812C4 16.5547 4.19531 16.75 4.46875 16.75H6.03125ZM13.5312 16.75C13.7656 16.75 14 16.5547 14 16.2812V14.7188C14 14.4844 13.7656 14.25 13.5312 14.25H11.9688C11.6953 14.25 11.5 14.4844 11.5 14.7188V16.2812C11.5 16.5547 11.6953 16.75 11.9688 16.75H13.5312Z"
      fill={fill}
      id="icon"
    />
  </svg>
)

export default IconCalendar
