import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useDetectSmall } from "../../hooks/useDetectMobile"
import NavIcon from "../Navigation/NavIcon"
import DesktopNavigation from "../Navigation/DesktopNavigation"
import MobileNavigation from "../Navigation/MobileNavigation"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const Navigation = ({
  shape,
  lightSkin,
  hoverType,
  hoverColor,
  overrideColors,
  headingTypography,
}) => {
  const { menu } = useStaticQuery(MAIN_NAV_QUERY)

  const isMobile = useDetectSmall()
  const [showMobileMenu, setShowMobileMenu] = useState(false)

  const hasMenuItems = menu.menuItems.nodes.length > 0

  return isMobile ? (
    <>
      {hasMenuItems && (
        <NavIcon
          lightSkin={lightSkin}
          hoverColor={hoverColor}
          isOpen={showMobileMenu}
          mobileMenuToggleHandler={() => setShowMobileMenu(!showMobileMenu)}
        />
      )}
      <MobileNavigation
        overrideColors={overrideColors}
        menu={menu}
        shape={shape}
        lightSkin={lightSkin}
        isOpen={showMobileMenu}
        toggle={() => setShowMobileMenu(!showMobileMenu)}
        hasMenuItems={hasMenuItems}
        hoverColor={hoverColor}
        headingTypography={headingTypography}
      />
    </>
  ) : (
    <DesktopNavigation
      menu={menu}
      shape={shape}
      lightSkin={lightSkin}
      hoverType={hoverType}
      hoverColor={hoverColor}
      hasMenuItems={hasMenuItems}
      headingTypography={headingTypography}
    />
  )
}
export default Navigation

///////////////////////////////////////
// 🔎 Queries
///////////////////////////////////////
const MAIN_NAV_QUERY = graphql`
  {
    menu: wpgraphql {
      menuItems(where: { location: MAIN }) {
        nodes {
          ...MenuFields
        }
      }
    }
  }
  fragment MenuFields on WPGraphQL_MenuItem {
    id
    url
    label
    target
    cssClasses
    connectedObject {
      __typename
    }
    childItems {
      nodes {
        id
        url
        label
        target
        cssClasses
        connectedObject {
          __typename
        }
      }
    }
  }
`
