import React from "react"

const IconDribbble = ({ fill }) => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 0.8125C4.64844 0.8125 0.3125 5.1875 0.3125 10.5C0.3125 15.8516 4.64844 20.1875 10 20.1875C15.3125 20.1875 19.6875 15.8516 19.6875 10.5C19.6875 5.1875 15.3125 0.8125 10 0.8125ZM16.3672 5.30469C17.5391 6.71094 18.2422 8.50781 18.2422 10.4609C17.9688 10.3828 15.2344 9.83594 12.5 10.1875C12.2656 9.64062 12.0703 9.17188 11.7578 8.54688C14.8438 7.29688 16.2109 5.53906 16.3672 5.30469ZM15.4688 4.32812C15.3125 4.5625 14.0625 6.24219 11.1328 7.33594C9.76562 4.83594 8.28125 2.80469 8.04688 2.49219C10.6641 1.86719 13.4375 2.53125 15.4688 4.32812ZM6.44531 3.03906C6.67969 3.35156 8.16406 5.38281 9.53125 7.84375C5.66406 8.85938 2.26562 8.85938 1.875 8.82031C2.42188 6.28125 4.14062 4.13281 6.44531 3.03906ZM1.71875 10.5391C1.71875 10.4609 1.71875 10.3828 1.71875 10.2656C2.07031 10.3047 6.09375 10.3438 10.1953 9.09375C10.4688 9.5625 10.6641 10.0312 10.8984 10.5C7.89062 11.3594 5.15625 13.7812 3.82812 16.0469C2.5 14.6016 1.71875 12.6484 1.71875 10.5391ZM4.88281 17.0625C5.78125 15.3047 8.125 13 11.4453 11.8672C12.6172 14.875 13.0859 17.4141 13.2031 18.1562C10.5469 19.2891 7.34375 18.9766 4.88281 17.0625ZM14.6094 17.375C14.5312 16.9062 14.0625 14.4844 13.0078 11.4766C15.5859 11.0859 17.8516 11.75 18.1641 11.8281C17.7734 14.1328 16.4453 16.125 14.6094 17.375Z"
      fill={fill}
      id="icon"
    />
  </svg>
)

export default IconDribbble
