import React from "react"

const IconMapMarked = ({ fill }) => (
  <svg
    width="23"
    height="21"
    viewBox="0 0 23 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.125 6.25C22.0469 6.25 21.9688 6.28906 21.8906 6.32812L15.25 8.75L14.8203 8.63281C15.7578 7.26562 16.5 5.85938 16.5 4.80469C16.5 2.14844 14.2344 0 11.5 0C8.72656 0 6.5 2.1875 6.5 4.80469C6.5 5.27344 6.65625 5.82031 6.89062 6.40625L1.03125 8.4375C0.5625 8.63281 0.25 9.10156 0.25 9.60938V19.375C0.25 19.7656 0.523438 20 0.875 20C0.953125 20 1.03125 20 1.10938 19.9609L7.75 17.5L14.4688 19.8828C14.9766 20.0391 15.5234 20.0781 16.0312 19.8828L21.9297 17.8125C22.4375 17.6562 22.75 17.1875 22.75 16.6797V6.875C22.75 6.52344 22.4375 6.25 22.125 6.25ZM7.125 16.4062L1.46094 18.4766L1.42188 9.64844L7.125 7.65625V16.4062ZM11.5 1.25C13.5312 1.25 15.25 2.85156 15.25 4.80469C15.25 5.85938 13.7266 8.28125 11.5 10.9375C9.23438 8.28125 7.75 5.85938 7.75 4.80469C7.75 2.85156 9.42969 1.25 11.5 1.25ZM8.375 16.4062V8.94531C9.3125 10.3516 10.4062 11.6016 10.9922 12.3047C11.1094 12.4609 11.3047 12.5 11.5 12.5C11.6562 12.5 11.8516 12.4609 11.9688 12.3047C12.4766 11.7188 13.2969 10.7812 14.0781 9.6875L14.625 9.88281V18.6328L8.375 16.4062ZM15.875 18.6328V9.88281L21.5 7.77344L21.5391 16.6406L15.875 18.6328Z"
      fill={fill}
      id="icon"
    />
  </svg>
)

export default IconMapMarked
