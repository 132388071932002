import React from "react"
import styled from "styled-components"
import { navigate } from "gatsby"

import { getColorLuminance } from "../../utils"
import getFlexiOptionsContent from "../../options/getFlexiOptionsContent"
import getFlexiOptionsBranding from "../../options/getFlexiOptionsBranding"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const Notice = ({ stack }) => {
  const { siteWideNotice } = getFlexiOptionsContent()
  const {
    shape,
    lightSkin,
    bodyTypography,
    primaryColor,
  } = getFlexiOptionsBranding()

  const hideNotice = () => {
    localStorage.setItem("flexi-notice-hide", true)
    setHide(true)
  }

  const [hide, setHide] = React.useState(false)
  React.useEffect(() => {
    setHide(localStorage.getItem("flexi-notice-hide"))
  }, [])

  return hide ? null : (
    <PrivacyNoticeWrapper
      stack={stack}
      shape={shape}
      lightSkin={lightSkin}
      top={siteWideNotice.position === "top"}
      className={siteWideNotice.position}
      primaryColor={primaryColor}
      bodyTypography={bodyTypography}
    >
      <p dangerouslySetInnerHTML={{ __html: siteWideNotice.text }} />
      <button className="exit" onClick={hideNotice}>
        hide
      </button>
      <button
        className="cta"
        onClick={() =>
          siteWideNotice.cta.isExternal
            ? (window.location.href = siteWideNotice.cta.link)
            : navigate(siteWideNotice.cta.link)
        }
      >
        {siteWideNotice.cta.label}
      </button>
    </PrivacyNoticeWrapper>
  )
}

export default Notice

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const PrivacyNoticeWrapper = styled.div`
  background: ${({ lightSkin, theme }) =>
    lightSkin ? theme.colors.white[100] : theme.colors.tusk[200]};
  p {
    color: ${({ lightSkin, theme }) =>
      lightSkin ? theme.colors.midnight[100] : theme.colors.midnight[300]};
    font-size: 0.85rem;
    margin: 0;
    line-height: 1.2;
  }
  @media (max-width: 840px) {
    width: calc(100vw - 70px);
    left: 0;
  }
  width: 770px;
  left: calc(50vw - 420px);
  position: fixed;
  ${({ top, stack }) =>
    top ? `top:15px;` : stack ? "bottom:110px;" : "bottom:15px;"}
  padding: 15px;
  border-radius: 4px;
  display: grid;
  grid-template-areas:
    "text exit"
    "cta cta";

  button.exit {
    grid-area: exit;
  }
  button.cta {
    grid-area: cta;
  }

  margin: 0 20px;
  grid-template-columns: 1fr auto;
  align-items: center;
  box-shadow: 0px 0px 16px rgba(20, 37, 40, 0.15);
  grid-gap: 20px;
  z-index: 9999;
  &.bottom-center {
    width: 770px;
    left: calc(50vw - 420px);
  }
  &.bottom-left {
    width: 500px;
    left: 0;
  }
  &.bottom-right {
    width: 500px;
    right: 0;
  }
  a {
    text-decoration: underline;
    color: ${({ lightSkin, theme }) =>
      lightSkin ? theme.colors.midnight[100] : theme.colors.midnight[300]};
    &:hover {
      color: ${({ primaryColor }) => primaryColor};
    }
  }
  button {
    padding: 8px;
    border-radius: 4px;
    color: #fff;
    background-color: ${({ primaryColor }) => primaryColor};
    border: none;
    text-transform: uppercase;
    transition: all 100ms ease-in-out;
    &:hover {
      background-color: ${({ primaryColor }) =>
        getColorLuminance(primaryColor, -0.1)};
      cursor: pointer;
    }
  }
  @media (max-width: 840px) {
    &.bottom-right,
    &.bottom-left,
    &.bottom-center {
      width: calc(100vw - 70px);
      left: 0;
    }
  }
`
