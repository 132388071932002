import React from "react"

const IconYouTube = ({ fill }) => (
  <svg
    width="22"
    height="15"
    viewBox="0 0 22 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.4453 2.38281C21.2109 1.44531 20.4688 0.703125 19.5703 0.46875C17.8906 0 11.25 0 11.25 0C11.25 0 4.57031 0 2.89062 0.46875C1.99219 0.703125 1.25 1.44531 1.01562 2.38281C0.546875 4.02344 0.546875 7.53906 0.546875 7.53906C0.546875 7.53906 0.546875 11.0156 1.01562 12.6953C1.25 13.6328 1.99219 14.3359 2.89062 14.5703C4.57031 15 11.25 15 11.25 15C11.25 15 17.8906 15 19.5703 14.5703C20.4688 14.3359 21.2109 13.6328 21.4453 12.6953C21.9141 11.0156 21.9141 7.53906 21.9141 7.53906C21.9141 7.53906 21.9141 4.02344 21.4453 2.38281ZM9.0625 10.7031V4.375L14.6094 7.53906L9.0625 10.7031Z"
      fill={fill}
      id="icon"
    />
  </svg>
)

export default IconYouTube
