import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { Link } from "gatsby"
import Headroom from "react-headroom"
import Navigation from "../Navigation"
import Inner from "../../dsm/layout/Inner"
import getFlexiOptionsBranding from "../../options/getFlexiOptionsBranding"
import getFlexiOptionsLayouts from "../../options/getFlexiOptionsLayouts"
import getWPSettings from "../../options/getWPSettings"
import {
  navTools,
  getFontFamily,
  getAssetColours,
  getInverseColour,
} from "../../utils"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const Header = () => {
  const {
    shape,
    lightSkin,
    defaultLogo,
    primaryColor,
    headingTypography,
  } = getFlexiOptionsBranding()
  const { generalSettingsTitle } = getWPSettings()
  const { headerConfig, mainNavigationConfig } = getFlexiOptionsLayouts()
  const {
    overrideHeaderColors,
    backgroundColour,
    textColour,
    showHeaderOnUpScroll,
  } = headerConfig
  // TESTING========================
  // To test without having to rebuild.
  // 1. comment out line 27
  // 2. comment out line 39
  // 3. uncomment line 37 & 38 and update values in array
  // const [hoverType, overrideHeaderColors] = ["background-colours", true] // testing
  // const { hoverColour, customColour } = mainNavigationConfig // testing
  const { hoverType, hoverColour, customColour } = mainNavigationConfig
  const overrideColors = overrideHeaderColors
    ? { background: backgroundColour, color: textColour }
    : null

  const withHeadroom = (add) => (children) =>
    add ? <Headroom>{children}</Headroom> : children

  return withHeadroom(showHeaderOnUpScroll)(
    <HeaderWrapper
      lightSkin={lightSkin}
      hoverType={hoverType}
      primaryColor={primaryColor}
      overrideColors={overrideColors}
      headingTypography={headingTypography}
      hoverColour={getAssetColours(hoverColour, customColour)}
    >
      <Inner>
        <div className="logo-wrapper">
          <Link to="/">
            {defaultLogo ? (
              <div className="force-logo-height">
                {defaultLogo.imageFile.childImageSharp ? (
                  <Img
                    alt={generalSettingsTitle}
                    style={{ maxHeight: "100%" }}
                    imgStyle={{ objectFit: "contain" }}
                    fluid={defaultLogo.imageFile.childImageSharp.fluid}
                  />
                ) : (
                  <img src={defaultLogo.sourceUrl} alt={generalSettingsTitle} />
                )}
              </div>
            ) : (
              <h1>{generalSettingsTitle}</h1>
            )}
          </Link>
        </div>
        <div className="menu-wrapper">
          <Navigation
            shape={shape}
            lightSkin={lightSkin}
            hoverType={hoverType}
            overrideColors={overrideColors}
            headingTypography={headingTypography}
            hoverColor={getAssetColours(hoverColour, customColour)}
          />
        </div>
      </Inner>
    </HeaderWrapper>
  )
}

export default Header

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////

const getMobileStyles = ({
  theme,
  overrideColors: o,
  hoverColor: hc,
  hoverType: ht,
  lightSkin,
}) => {
  var border_color = lightSkin
    ? theme.colors.tusk[300]
    : theme.colors.midnight[100]

  // return ``
  return `border-bottom: 1px solid ${border_color}`
}
const desktopStyles = ({
  overrideColors,
  hoverType: ht,
  hoverColour: hc,
  lightSkin,
  theme,
}) => `a,
  div.a {
    /* background & colour */
    background: ${navTools.getBackground({ overrideColors, lightSkin, theme })};
    color: ${navTools.getColor({ overrideColors, lightSkin, theme })};

    /* Bottom border */
    ${
      ht === "background-colour"
        ? `border-bottom: 1px solid ${navTools.getBackground({
            overrideColors,
            lightSkin,
            theme,
          })};`
        : `border-bottom: 3px solid ${navTools.getBackground({
            overrideColors,
            lightSkin,
            theme,
          })};`
    }

    /* Active & hover states */
  &:hover,
  &[aria-current="page"] {
      ${
        ht === "background-colour"
          ? `background-color:${hc};
          border-bottom: 3px solid ${hc};
          color: ${getInverseColour(hc)};`
          : `border-bottom:3px solid ${hc};`
      }
    }
  }`

const HeaderWrapper = styled.header`
  padding: 0;
  min-height: 75px;
  /* Social icons (mobile) */
  .social-icons {
    a:hover {
      background-color: unset !important;
      border-bottom: unset !important;
      filter: brightness(1.2);
    }
    a svg path {
      transition: 02s all ease-in-out;
      fill: ${navTools.getColor} !important;
    }
  }
  /* up/down menu chevron (mobile) */
  .sub svg path {
    fill: ${navTools.getColor};
  }
  /* burger menu color */
  .nav-icon span {
    /* FYI: this is correct even though it looks odd */
    background: ${navTools.getColor};
  }
  &,
  /* entire navigation row background & colour*/
  .main-nav {
    background: ${navTools.getBackground};
    color: ${navTools.getColor};

    &.mobile > div > ul {
      a,
      div.a {
        ${getMobileStyles}
      }
    }

    .social-icons {
      svg path {
        fill: ${({ hoverType: ht, hoverColour: hc }) =>
          ht === "background-colour"
            ? `${getInverseColour(hc)} !important`
            : ""};
      }
    }

    /* Links and sub links */
    /* Duplicate on account of css specificity issues,
    same styles applied in two contexts */
    ${desktopStyles}
    & > div > ul {
      ${desktopStyles}
    }
  }

  .inner {
    display: grid;
    grid-template-columns: 200px 1fr;
    grid-gap: 20px;
    align-items: center;
    height: 100%;
  }
  .logo-wrapper {
    padding: 10px 0;
    z-index: 9999;
    img {
      object-position: left center !important;
      max-height: -webkit-fill-available;
    }
    .force-logo-height {
      max-height: 100%;
      height: 50px;
      display: grid;
    }
    a {
      text-decoration: none;
      h1 {
        font-size: 1rem;
        font-family: ${({ headingTypography }) =>
          getFontFamily(headingTypography.fontFamily)};
        color: ${({ primaryColor }) => primaryColor};
      }
    }
  }
  .menu-wrapper {
    justify-self: flex-end;
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    height: 75px;
  }
`
