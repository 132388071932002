import React from "react"
import styled from "styled-components"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const Inner = ({ children }) => (
  <InnerWrapper className="inner">{children}</InnerWrapper>
)
export default Inner

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const InnerWrapper = styled.div`
  max-width: ${({ theme }) => theme.maxWidth};
  margin: 0 auto;
  padding: 0 20px;
`
