import React from "react"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"

import Button from "../../../dsm/buttons"
import Inner from "../../../dsm/layout/Inner"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const PageHeaderLayoutB = ({
  title,
  buttons,
  vSpacing,
  alignment,
  introText,
  textColour,
  backgroundImage,
  colorHasPriority,
  backgroundColour,
  showGradientOverlay,
  isPreview,
}) => {
  // Determine if we need a wrapper for a background color or image
  let Wrapper = WrapperColor
  let wrapperProps = {}
  // if local page has a background colour override global image
  if (colorHasPriority) {
    wrapperProps = {
      backgroundColour: backgroundColour,
    }
  } else {
    Wrapper = WrapperImage
    wrapperProps = isPreview
      ? { background: `background: url(${isPreview});`, as: "div" }
      : {
          fluid: backgroundImage.imageFile.childImageSharp.fluid,
          backgroundColour: backgroundColour,
        }
  }
  return (
    <Wrapper {...wrapperProps}>
      <Section
        vSpacing={vSpacing}
        alignment={alignment}
        textColour={textColour}
        showGradientOverlay={showGradientOverlay}
      >
        <Inner>
          <h1>{title}</h1>
          <div
            className="intro"
            dangerouslySetInnerHTML={{ __html: introText }}
          />
          {buttons && buttons.length > 0 && (
            <div className="button-row">
              {buttons.map((button, i) => (
                <Button
                  key={i}
                  to={button.link}
                  label={button.label}
                  isExternal={button.isExternal}
                  type={button.type}
                  icon={button.icon}
                  color={button.buttonColour || textColour}
                />
              ))}
            </div>
          )}
        </Inner>
      </Section>
    </Wrapper>
  )
}
export default PageHeaderLayoutB

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const WrapperImage = styled(BackgroundImage)`
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  background-color: ${({ backgroundColour }) => backgroundColour};
  ${({ background }) => background};
`
const WrapperColor = styled.div`
  background-color: ${({ backgroundColour }) => backgroundColour};
`
const Section = styled.section`
  padding: ${({ vSpacing }) => `${vSpacing} 0`};
  background: ${({ showGradientOverlay }) =>
    showGradientOverlay
      ? `linear-gradient(
    115deg,
    rgba(20, 35, 50, 0.60) 25%,
    rgba(25, 25, 50, 0) 75%
  )`
      : `none`};
  /* margin-top: -1px; */
  text-align: ${({ alignment }) =>
    alignment === "center" ? "center" : "left"};
  .inner {
    display: grid;
    justify-content: ${({ alignment }) => alignment};
  }
  .button-row {
    display: flex;
    justify-content: ${({ alignment }) => alignment};
    a {
      margin: 25px 10px 0 0;
    }
  }
  color: ${({ textColour }) => textColour};
  h1 {
    color: ${({ textColour }) => textColour};
    margin: 0;
  }
  .intro {
    max-width: 550px;
  }
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    .button-row a {
      margin: 25px 0 0 0;
    }
  }
`
