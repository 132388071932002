import React from "react"
import styled from "styled-components"

import MenuLink from "./MenuLink"
import { getFontFamily, getInverseColour } from "../../utils"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const DesktopNavigation = ({
  menu,
  shape,
  lightSkin,
  hoverType,
  hoverColor,
  hasMenuItems,
  headingTypography,
}) =>
  hasMenuItems && (
    <NavWrapper
      shape={shape}
      lightSkin={lightSkin}
      hoverColor={hoverColor}
      headingTypography={headingTypography}
    >
      {menu.menuItems.nodes.map((menuItem) => (
        <MenuLink key={menuItem.id} menuItem={menuItem} />
      ))}
    </NavWrapper>
  )
export default DesktopNavigation

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const NavWrapper = styled.nav`
  a {
    text-decoration: none;
    font-family: ${({ headingTypography }) =>
      getFontFamily(headingTypography.fontFamily)};
    font-weight: ${({ headingTypography }) => headingTypography.h1.fontWeight};
    font-size: 1.15rem;
    padding: 22px 20px 22px 20px;
    min-height: calc(75px - 47px);
    display: inline-block;
    transition: all 200ms ease-in-out;
    &.cta {
      color: ${({ hoverColor }) => getInverseColour(hoverColor)};
      background-color: ${({ hoverColor }) => hoverColor};
      border: none !important;
      padding: 10px 22px;
      margin-left: 10px;
      border-radius: ${({ shape }) => shape};
      &:hover {
        transform: translateY(-3px);
      }
    }
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    a {
      font-style: 1rem;
      padding: 23px 14px 22px 14px;
    }
  }
`
