export default {
  white: {
    "100": "#fff",
  },
  black: {
    "100": "#000",
  },
  tusk: {
    "100": "#FCFCFC",
    "200": "#F5F5F5",
    "300": "#E5E5E6",
    "400": "#C3C4C5",
    "500": "#ACADAF",
    "600": "#9A9CA1",
  },
  midnight: {
    "100": "#323753",
    "200": "#2C3049",
    "300": "#262940",
    "400": "#212336",
    "500": "#1A1C2B",
    "600": "#12131B",
  },
  orange: {
    "100": "#EB9C5B",
    "200": "#EA934D",
    "300": "#E88A3E",
    "400": "#E6802D",
    "500": "#EE8059",
    "600": "#E15A2D",
  },
  yellow: {
    "100": "#F9D886",
    "200": "#F7CE68",
    "300": "#F6C54C",
    "400": "#F5BB2E",
    "500": "#F2A63C",
    "600": "#F0961A",
  },
  blue: {
    "100": "#83BCCE",
    "200": "#6DB0C5",
    "300": "#57A4BC",
    "400": "#4696AF",
    "500": "#3D8399",
    "600": "#32768B",
  },
  teal: {
    "100": "#9AD0BD",
    "200": "#85C6AF",
    "300": "#6FBCA1",
    "400": "#5BB394",
    "500": "#4AA082",
    "600": "#428E73",
  },
  info: {
    "100": "#E5F6FE",
    "200": "#CEEEFD",
    "300": "#B0E4FC",
    "400": "#1C8CFD",
    "500": "#027AF3",
    "600": "#026EDB",
  },
  danger: {
    "100": "#FFE7DF",
    "200": "#FFD5C7",
    "300": "#FFBEA8",
    "400": "#E26F78",
    "500": "#DE5964",
    "600": "#DA4652",
  },
  warn: {
    "100": "#FFF5D4",
    "200": "#FFEEB8",
    "300": "#FFE58F",
    "400": "#E69928",
    "500": "#CE8418",
    "600": "#B77515",
  },
  success: {
    "100": "#DBFBD5",
    "200": "#ADE9AF",
    "300": "#8DE48C",
    "400": "#27C94B",
    "500": "#23B343",
    "600": "#1FA03C",
  },
}
