import React from "react"
import styled from "styled-components"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const NavIcon = ({
  isOpen,
  lightSkin,
  hoverColor,
  mobileMenuToggleHandler,
}) => {
  const classes = ["nav-icon"]
  if (isOpen) {
    classes.push("open")
  }
  return (
    <IconWrapper
      className={classes.join(" ")}
      onClick={mobileMenuToggleHandler}
      lightSkin={lightSkin}
    >
      <Icon
        className={classes.join(" ")}
        lightSkin={lightSkin}
        hoverColor={hoverColor}
      >
        <span />
        <span />
        <span />
        <span />
      </Icon>
    </IconWrapper>
  )
}
export default NavIcon

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const IconWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-items: center;
  padding: 25px 25px 26px 25px;
  transition: background 400ms ease-in-out;
  border-left: 1px solid
    ${({ theme, lightSkin }) =>
      lightSkin ? theme.colors.tusk[300] : theme.colors.midnight[100]};
  position: relative;
  top: -2px;
  right: -20px;
  z-index: 99999;
  &:hover {
    cursor: pointer;
  }
  &.open {
  }
`

const Icon = styled.div`
  margin: 0;
  padding: 0;
  width: 30px;
  height: 25px;
  position: relative;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    border-radius: 10px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
  }
  &.open span {
    background: ${({ hoverColor }) => hoverColor};
  }
  span:nth-child(1) {
    top: 0px;
  }
  span:nth-child(2),
  span:nth-child(3) {
    top: 10px;
  }
  span:nth-child(4) {
    top: 20px;
  }
  &.open span:nth-child(1) {
    top: 10px;
    width: 0%;
    left: 50%;
  }
  &.open span:nth-child(2) {
    transform: rotate(45deg);
  }
  &.open span:nth-child(3) {
    transform: rotate(-45deg);
  }
  &.open span:nth-child(4) {
    top: 10px;
    width: 0%;
    left: 50%;
  }
`
