import React from "react"
import styled from "styled-components"

import getFlexiOptionsContent from "../../options/getFlexiOptionsContent"
import getFlexiOptionsBranding from "../../options/getFlexiOptionsBranding"
import { getColorLuminance } from "../../utils"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const PrivacyNotice = ({ hideCookieNotice }) => {
  const { cookiesNotice } = getFlexiOptionsContent()
  const {
    lightSkin,
    shape,
    bodyTypography,
    primaryColor,
  } = getFlexiOptionsBranding()

  const handleAccept = () => {
    hideCookieNotice()
    localStorage.setItem("flexi-cookies-hide", true)
  }

  return (
    <PrivacyNoticeWrapper
      shape={shape}
      lightSkin={lightSkin}
      className={cookiesNotice.position}
      primaryColor={primaryColor}
      bodyTypography={bodyTypography}
    >
      <p dangerouslySetInnerHTML={{ __html: cookiesNotice.message }} />
      <button onClick={handleAccept}>{cookiesNotice.buttonLabel}</button>
    </PrivacyNoticeWrapper>
  )
}

export default PrivacyNotice

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const PrivacyNoticeWrapper = styled.div`
  background: ${({ lightSkin, theme }) =>
    lightSkin ? theme.colors.white[100] : theme.colors.tusk[200]};
  p {
    color: ${({ lightSkin, theme }) =>
      lightSkin ? theme.colors.midnight[100] : theme.colors.midnight[300]};
    font-size: 0.85rem;
    margin: 0;
    line-height: 1.2;
  }
  position: fixed;
  bottom: 15px;
  padding: 15px;
  border-radius: 4px;
  display: grid;
  margin: 0 20px;
  grid-template-columns: 1fr auto;
  align-items: center;
  box-shadow: 0px 0px 16px rgba(20, 37, 40, 0.15);
  grid-gap: 20px;
  z-index: 9999;
  &.bottom-center {
    width: 770px;
    left: calc(50vw - 420px);
  }
  &.bottom-left {
    width: 500px;
    left: 0;
  }
  &.bottom-right {
    width: 500px;
    right: 0;
  }
  a {
    text-decoration: underline;
    color: ${({ lightSkin, theme }) =>
      lightSkin ? theme.colors.midnight[100] : theme.colors.midnight[300]};
    &:hover {
      color: ${({ primaryColor }) => primaryColor};
    }
  }
  button {
    padding: 8px;
    border-radius: 4px;
    color: #fff;
    background-color: ${({ primaryColor }) => primaryColor};
    border: none;
    text-transform: uppercase;
    transition: all 100ms ease-in-out;
    &:hover {
      background-color: ${({ primaryColor }) =>
        getColorLuminance(primaryColor, -0.1)};
      cursor: pointer;
    }
  }
  @media (max-width: 840px) {
    &.bottom-right,
    &.bottom-left,
    &.bottom-center {
      width: calc(100vw - 70px);
      left: 0;
    }
  }
`
