import React, { useState, useEffect } from "react"
import styled from "styled-components"

import "./layout.css"
import "./generic-styles.css"
import Footer from "../../components/Footer"
import Header from "../../components/Header"
import Notice from "../../components/Notice"
import ThemeConfig from "./ThemeConfig"
import PrivacyNotice from "../../components/PrivacyNotice"
import FirebaseProvider from "../../components/Firebase/FirebaseProvider"
import SimpleReactLightbox from "simple-react-lightbox"
import getFlexiOptionsAdmin from "../../options/getFlexiOptionsAdmin"
import getFlexiOptionsContent from "../../options/getFlexiOptionsContent"
import getFlexiOptionsBranding from "../../options/getFlexiOptionsBranding"
import getFlexiOptionsLayouts from "../../options/getFlexiOptionsLayouts"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const Layout = ({ children }) => {
  const lightSkin = getFlexiOptionsBranding()?.lightSkin
  const packageType = getFlexiOptionsAdmin()?.packageType
  const { cookiesNotice, siteWideNotice } = getFlexiOptionsContent()

  var {
    websiteSkeleton: { mainBgColor },
  } = getFlexiOptionsLayouts()

  // Cookies Notice
  const [showCookieNotice, setCookieNotice] = useState(false)
  useEffect(() => {
    setCookieNotice(!localStorage.getItem("flexi-cookies-hide"))
  }, [])
  const hideCookieNotice = () => {
    setCookieNotice(false)
  }

  return (
    <FirebaseProvider>
      <ThemeConfig>
        <Header />
        <SimpleReactLightbox>
          <Main
            lightSkin={lightSkin}
            packageType={packageType}
            mainBgColor={mainBgColor}
          >
            {children}
          </Main>
        </SimpleReactLightbox>
        <Footer />
        {siteWideNotice.isActive && (
          <Notice stack={cookiesNotice.isVisible && showCookieNotice} />
        )}
        {cookiesNotice.isVisible && showCookieNotice && (
          <PrivacyNotice hideCookieNotice={hideCookieNotice} />
        )}
      </ThemeConfig>
    </FirebaseProvider>
  )
}
export default Layout

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const Main = styled.main`
  background: ${({ theme, lightSkin, mainBgColor }) =>
    mainBgColor
      ? mainBgColor
      : lightSkin
      ? theme.colors.white[100]
      : theme.colors.midnight[300]};
  padding: 0;
  min-height: calc(100vh - 100px - 75px);
`
