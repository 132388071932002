import React from "react"

const IconMobile = ({ fill }) => (
  <svg
    width="13"
    height="21"
    viewBox="0 0 13 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.65625 18C7.89062 18 8.125 17.8047 8.125 17.5312V17.2188C8.125 16.9844 7.89062 16.75 7.65625 16.75H4.84375C4.57031 16.75 4.375 16.9844 4.375 17.2188V17.5312C4.375 17.8047 4.57031 18 4.84375 18H7.65625ZM12.5 2.375C12.5 1.35938 11.6406 0.5 10.625 0.5H1.875C0.820312 0.5 0 1.35938 0 2.375V18.625C0 19.6797 0.820312 20.5 1.875 20.5H10.625C11.6406 20.5 12.5 19.6797 12.5 18.625V2.375ZM11.25 2.375V18.625C11.25 18.9766 10.9375 19.25 10.625 19.25H1.875C1.52344 19.25 1.25 18.9766 1.25 18.625V2.375C1.25 2.0625 1.52344 1.75 1.875 1.75H10.625C10.9375 1.75 11.25 2.0625 11.25 2.375Z"
      fill={fill}
      id="icon"
    />
  </svg>
)

export default IconMobile
