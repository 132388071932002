import React from "react"
import { ThemeProvider, createGlobalStyle } from "styled-components"
import { Helmet } from "react-helmet"

import colors from "../colors"
import breakpoints from "../breakpoints"
import getFlexiOptionsBranding from "../../options/getFlexiOptionsBranding"
import { getFontFamily, needsFontEmbed, getFontEmbed } from "../../utils"
import getFlexiOptionsLayouts from "../../options/getFlexiOptionsLayouts"

///////////////////////////////////////
// 💅 Theme Set-up
///////////////////////////////////////
export const theme = {
  maxWidth: "1260px",
  maxWidthSkinny: "800px",
  ...breakpoints,
  colors,
}

const GlobalStyle = createGlobalStyle`
  h1,h2,h3,h4,h5,h6 {
    font-family: ${({ headingTypography }) =>
      getFontFamily(headingTypography.fontFamily)};
    color: ${({ lightSkin, theme }) =>
      lightSkin ? theme.colors.midnight[500] : theme.colors.tusk[100]};
    margin: 1.5rem 0 0.5rem 0;
  }
  h1 {
    font-size:  ${({ headingTypography }) => headingTypography.h1.fontSize};
    line-height:${({ headingTypography }) => headingTypography.h1.lineHeight};
  }
  h2 {
    font-size:  ${({ headingTypography }) => headingTypography.h2.fontSize};
    line-height:${({ headingTypography }) => headingTypography.h2.lineHeight};
  }
  h3 {
    font-size:  ${({ headingTypography }) => headingTypography.h3.fontSize};
    line-height:${({ headingTypography }) => headingTypography.h3.lineHeight};
  }
  h4 {
    font-size:  ${({ headingTypography }) => headingTypography.h4.fontSize};
    line-height:${({ headingTypography }) => headingTypography.h4.lineHeight};
  }
  h5 {
    font-size:  ${({ headingTypography }) => headingTypography.h5.fontSize};
    line-height:${({ headingTypography }) => headingTypography.h5.lineHeight};
  }
  h6 {
    font-size:  ${({ headingTypography }) => headingTypography.h6.fontSize};
    line-height:${({ headingTypography }) => headingTypography.h6.lineHeight};
    text-transform: uppercase;
  }
  html {
    color: ${({ lightSkin, theme }) =>
      lightSkin ? theme.colors.midnight[500] : theme.colors.tusk[100]};
    font-size: ${({ bodyTypography }) => bodyTypography.fontSize};
    font-family: ${({ bodyTypography }) =>
      getFontFamily(bodyTypography.fontFamily)};
  }
  p {
    margin: 0.5rem 0 1rem 0;
    line-height: ${({ bodyTypography }) => bodyTypography.lineHeight};
    font-size:1rem;
    &.mega {
      font-size:1.375rem;
    }
    &.large {
      font-size:1.125rem;
    }
    &.small {
      font-size:0.875rem;
    }
    &.tiny {
      font-size:0.75rem;
    }
  }
  img {
    max-width: 100%;
    height:auto;
  }
  main a {
    color: ${({ linkColor }) => linkColor};
    text-decoration:none;
    border-bottom:1px dotted ${({ linkColor }) => linkColor};
    padding:0 2px;
    transition: all 200ms ease-in-out;
    &:hover {
      border-bottom:1px solid ${({ linkColor }) => linkColor};
    }
  }
`

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const ThemeConfig = ({ children }) => {
  const {
    lightSkin,
    linkColor,
    bodyTypography,
    headingTypography,
  } = getFlexiOptionsBranding()
  const { customCss } = getFlexiOptionsLayouts()
  return (
    <ThemeProvider theme={theme}>
      {customCss && <style>{customCss}</style>}
      {needsFontEmbed(headingTypography.fontFamily) && (
        <Helmet>
          <link
            href={`https://fonts.googleapis.com/css2?family=${getFontEmbed(
              headingTypography.fontFamily
            )}&display=swap`}
            rel="stylesheet"
          ></link>
        </Helmet>
      )}
      {needsFontEmbed(bodyTypography.fontFamily) && (
        <Helmet>
          <link
            href={`https://fonts.googleapis.com/css2?family=${getFontEmbed(
              bodyTypography.fontFamily
            )}&display=swap`}
            rel="stylesheet"
          ></link>
        </Helmet>
      )}
      <>
        <GlobalStyle
          linkColor={linkColor}
          lightSkin={lightSkin}
          bodyTypography={bodyTypography}
          headingTypography={headingTypography}
        />
        <>{children}</>
      </>
    </ThemeProvider>
  )
}

export default ThemeConfig
