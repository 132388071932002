import React from "react"

const IconArrowLeft = ({ fill }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.30273 0.146484L9.57617 0.419922C9.77148 0.615234 9.77148 0.888672 9.57617 1.08398L2.62305 8.03711H17.2715C17.5449 8.03711 17.7402 8.27148 17.7402 8.50586V8.89648C17.7402 9.16992 17.5449 9.36523 17.2715 9.36523H2.62305L9.57617 16.3574C9.77148 16.5527 9.77148 16.8262 9.57617 17.0215L9.30273 17.2949C9.10742 17.4902 8.83398 17.4902 8.63867 17.2949L0.396484 9.05273C0.201172 8.85742 0.201172 8.58398 0.396484 8.38867L8.63867 0.146484C8.83398 -0.0488281 9.10742 -0.0488281 9.30273 0.146484Z"
      fill={fill}
      id="icon"
    />
  </svg>
)

export default IconArrowLeft
