import { graphql, useStaticQuery } from "gatsby"

export default () => {
  const data = useStaticQuery(graphql`
    {
      wpgraphql {
        flexisiteOptionsLayoutConfiguration {
          optionsLayouts {
            customCss
            sliderConfig {
              duration
              showDots
              showArrows
              assetColours
              showProgressBar
            }
            websiteSkeleton {
              mainBgColor
            }
            mainNavigationConfig {
              hoverType
              hoverColour
              customColour
            }
            headerConfig {
              backgroundColour
              overrideHeaderColors
              showHeaderOnUpScroll
              textColour
            }
            pageHeaderConfig {
              layout
              alignment
              textColour
              verticalSpacing
              showBreadcrumbs
              backgroundColour
              showGradientOverlay
              backgroundImage {
                sourceUrl
                altText
                imageFile {
                  childImageSharp {
                    fluid(quality: 90, cropFocus: CENTER) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
            footerConfig {
              layout
              textColour
              hoverColour
              backgroundColour
            }
            quoteConfig {
              layout
              alignment
              textColour
              contentLayout
              quoteIconColour
              verticalSpacing
              backgroundColour
              backgroundImage {
                sourceUrl
                altText
                imageFile {
                  childImageSharp {
                    fluid(quality: 90, cropFocus: CENTER) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
            blogHeaderConfig {
              showDate
              showAuthor
              textColour
              showOverlay
              showCategories
              verticalSpacing
              singlePageHeaderLayout
            }
          }
        }
      }
    }
  `)

  return data.wpgraphql.flexisiteOptionsLayoutConfiguration.optionsLayouts
}
