import React from "react"

const IconPaperPlane = ({ fill }) => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.125 0.695286L1.62499 10.7734C0.726555 11.2812 0.80468 12.6093 1.74218 13L7.24999 15.2656V19.25C7.24999 20.4609 8.6953 20.9687 9.43749 20.0703L11.8203 17.1797L16.7422 19.2109C17.4844 19.5234 18.3437 19.0547 18.4609 18.2343L20.9609 1.94529C21.1172 0.929661 20.0234 0.148411 19.125 0.695286ZM8.49999 19.25V15.8125L10.6094 16.6718L8.49999 19.25ZM17.25 18.0781L9.16405 14.7187L16.9766 5.53904C17.1719 5.30466 16.8594 4.99216 16.625 5.18747L6.66405 13.7031L2.24999 11.8672L19.75 1.74997L17.25 18.0781Z"
      fill={fill}
      id="icon"
    />
  </svg>
)

export default IconPaperPlane
