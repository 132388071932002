import React from "react"

const IconLinkedIn = ({ fill }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.90625 18V6.32031H0.273438V18H3.90625ZM2.07031 4.75781C3.24219 4.75781 4.17969 3.78125 4.17969 2.60938C4.17969 1.47656 3.24219 0.539062 2.07031 0.539062C0.9375 0.539062 0 1.47656 0 2.60938C0 3.78125 0.9375 4.75781 2.07031 4.75781ZM17.5 18V11.5938C17.5 8.46875 16.7969 6.04688 13.125 6.04688C11.3672 6.04688 10.1953 7.02344 9.6875 7.92188H9.64844V6.32031H6.17188V18H9.80469V12.2188C9.80469 10.6953 10.0781 9.25 11.9531 9.25C13.8281 9.25 13.8672 10.9688 13.8672 12.3359V18H17.5Z"
      fill={fill}
      id="icon"
    />
  </svg>
)

export default IconLinkedIn
