import getFlexiOptionsBranding from "../options/getFlexiOptionsBranding"
import React from "react"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"

///////////////////////////////////////////////////////////////////////////////
// 👌 Helper Functions
///////////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////
// Get Slug
///////////////////////////////////////////////////////////////////////////////
export const getSlug = (str) => {
  str = str.replace(/^\s+|\s+$/g, "") // trim
  str = str.toLowerCase()

  // remove accents, swap ñ for n, etc
  var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;"
  var to = "aaaaeeeeiiiioooouuuunc------"
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i))
  }

  str = str
    .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-") // collapse dashes

  return str
}

///////////////////////////////////////////////////////////////////////////////
// Format a phone number to be a link
///////////////////////////////////////////////////////////////////////////////
export const formatPhoneToLink = (str, isExternal = false) => {
  return `<a href="tel:${str
    .replace("(", "")
    .replace(")", "")
    .replace(/-/g, "")
    .replace(/ /g, "")}" ${
    isExternal ? `target="_blank" rel="noopener noreferrer"` : `target="_self"`
  }>${str}</a>`
}

export const formatPhoneToWhatsappLink = (num) => {
  return formatPhoneToLink(num, true)
    .replace("+", "")
    .replace("tel:", "https://wa.me/")
    .replace("wa.me/0", "wa.me/27")
}

///////////////////////////////////////////////////////////////////////////////
// Format an Email to be a link
///////////////////////////////////////////////////////////////////////////////
export const formatEmailToLink = (str, isExternal = false) =>
  `<a href="mailto:${str}" ${
    isExternal ? `target="_blank" rel="noopener noreferrer"` : `target="_self"`
  }>${str}</a>`

///////////////////////////////////////////////////////////////////////////////
// Create Local Links by removing our API url in any url
///////////////////////////////////////////////////////////////////////////////
export const CreateLocalLink = (url) => {
  if (url === "#") {
    return null
  }
  let newUri = url.replace(process.env.GATSBY_WP_URL, "").replace("_", "-")
  return newUri
}

///////////////////////////////////////////////////////////////////////////////
// Create an Absolute link by replacing our API url with the live domain
///////////////////////////////////////////////////////////////////////////////
export const createAbsoluteLink = (url) => {
  if (url === "#") {
    return null
  }
  let newUri = url.replace(
    process.env.GATSBY_WP_URL,
    process.env.GATSBY_SITE_URL
  )
  return newUri
}

///////////////////////////////////////////////////////////////////////////////
// Calculate a new shade of a color
///////////////////////////////////////////////////////////////////////////////
export const getColorLuminance = (hex, lum) => {
  // validate hex string
  hex = String(hex).replace(/[^0-9a-f]/gi, "")
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
  }
  lum = lum || 0

  // convert to decimal and change luminosity
  var rgb = "#",
    c,
    i
  for (i = 0; i < 3; i++) {
    c = parseInt(hex.substr(i * 2, 2), 16)
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16)
    rgb += ("00" + c).substr(c.length)
  }

  return rgb
}

///////////////////////////////////////////////////////////////////////////////
// Determine the accessible colour of text
///////////////////////////////////////////////////////////////////////////////
export const getInverseColour = (hex) => {
  let color = hex.replace(/#/g, "")
  // if shorthand notation is passed in
  if (color.length !== 6) {
    color = `${color}${color}`
  }
  // rgb values
  var r = parseInt(color.substr(0, 2), 16)
  var g = parseInt(color.substr(2, 2), 16)
  var b = parseInt(color.substr(4, 2), 16)
  var yiq = (r * 299 + g * 587 + b * 114) / 1000
  return yiq >= 128 ? "#000000" : "#FFFFFF"
}

///////////////////////////////////////////////////////////////////////////////
// Fonts helper functions
///////////////////////////////////////////////////////////////////////////////
export const getFontFamily = (font) => {
  const parts = font.split("[")
  return parts[0]
}
export const needsFontEmbed = (font) => {
  const parts = font.split("[")
  return parts.length > 1
}
export const getFontEmbed = (font) => {
  const parts = font.split("[")
  return parts[1].replace("]", "")
}

///////////////////////////////////////////////////////////////////////////////
// Vertical Spacing
///////////////////////////////////////////////////////////////////////////////
export const getVerticalSpacing = (verticalSpacing) => {
  let spacing = ""
  switch (verticalSpacing) {
    case "none":
      spacing = "0"
      break
    case "small":
      spacing = "30px"
      break
    case "medium":
      spacing = "60px"
      break
    case "large":
      spacing = "90px"
      break
    case "xl":
      spacing = "120px"
      break
    case "xxl":
      spacing = "150px"
      break
    default:
      spacing = "60px"
      break
  }
  return spacing
}
///////////////////////////////////////////////////////////////////////////////
// Get Asset Colours
///////////////////////////////////////////////////////////////////////////////
export const getAssetColours = (assetColours, custom) => {
  let hex = ""
  const { primaryColor, secondaryColor } = getFlexiOptionsBranding()
  switch (assetColours) {
    case "light":
      hex = "#FFFFFF"
      break
    case "dark":
      hex = "#000000"
      break
    case "primary":
      hex = primaryColor
      break
    case "secondary":
      hex = secondaryColor
      break
    case "custom":
      hex = custom
      break
    default:
      hex = "#000000"
      break
  }
  return hex
}

// Main Nav
const dark = (theme) => theme.colors.midnight["500"]
const light = (theme) => theme.colors.white["100"]

function getNavBackgroundColor({ overrideColors, lightSkin, theme }) {
  return overrideColors
    ? overrideColors.background
    : lightSkin
    ? light(theme)
    : dark(theme)
}

function getNavTextColor({ overrideColors, lightSkin, theme }) {
  return overrideColors
    ? overrideColors.color
    : lightSkin
    ? dark(theme)
    : light(theme)
}

export const navTools = {
  getBackground: getNavBackgroundColor,
  getColor: getNavTextColor,
}

// Wrapper

const WrapperImage = styled(BackgroundImage)`
  ${({ background }) => background}
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
`
const WrapperColor = styled.div`
  background-color: ${({ backgroundColour }) => backgroundColour};
`

export function getWrapper({ backgroundImage, backgroundColour }) {
  const isPreview = backgroundImage?.mediaItemUrl
  // Determine if we need a wrapper for a background color or image
  let Wrapper = WrapperColor
  let wrapperProps = {}
  // if local page has a background colour override global image
  if (!backgroundImage) {
    wrapperProps = {
      backgroundColour: backgroundColour,
    }
  } else {
    Wrapper = WrapperImage
    wrapperProps = isPreview
      ? {
          background: `background-repeat: no-repeat;
                        background-size: cover;
                        background-position: top center;
                        background: url(${isPreview});`,
          as: "div",
        }
      : {
          fluid: backgroundImage.imageFile.childImageSharp.fluid,
        }
  }
  return (props) => <Wrapper {...props} {...wrapperProps} />
}
