import React from "react"

const IconVimeo = ({ fill }) => (
  <svg
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.4609 3.51562C17.5781 1.24999 16.7188 0.0781184 14.9219 0.0390559C12.5 -0.0390691 10.8594 1.32812 10 4.14062C11.0938 3.67187 12.6562 3.55468 12.5 5.35156C12.4219 5.93749 12.0312 6.83593 11.2891 7.96874C9.25781 11.2109 8.75 10.1172 7.65625 3.04687C7.34375 1.05468 6.48438 0.117181 5.11719 0.234368C3.90625 0.351556 1.99219 2.30468 0 4.10156L0.820312 5.15624C1.60156 4.60937 2.03125 4.33593 2.1875 4.33593C3.32031 4.33593 3.90625 7.26562 5.27344 12.3437C5.97656 14.2187 6.83594 15.1562 7.85156 15.1562C9.53125 15.1562 11.5234 13.5937 13.9062 10.5078C16.2109 7.53906 17.3828 5.23437 17.4609 3.51562Z"
      fill={fill}
      id="icon"
    />
  </svg>
)

export default IconVimeo
