import React from "react"
import styled from "styled-components"
import InnerHTML from "dangerously-set-html-content"

import Icon from "../../../dsm/icons/icon"
import Inner from "../../../dsm/layout/Inner"
import MenuLink from "../../Navigation/MenuLink"
import SocialIcons from "../../shared/SocialIcons"
import { getFontFamily, formatPhoneToWhatsappLink } from "../../../utils"
import { formatPhoneToLink, formatEmailToLink } from "../../../utils"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const FooterLayoutA = ({
  menu,
  lightSkin,
  textColour,
  hoverColour,
  footerContent,
  contactDetails,
  bodyTypography,
  backgroundColour,
}) => {
  const finalTextColour = textColour || (lightSkin ? "#F5F5F5" : "#E5E5E6")

  return (
    <FooterWrapper
      lightSkin={lightSkin}
      hoverColour={hoverColour}
      finalTextColour={finalTextColour}
      backgroundColour={backgroundColour}
    >
      <Inner>
        <DetailStart bodyTypography={bodyTypography} hoverColour={hoverColour}>
          <div className="top-row">
            {contactDetails.emails && (
              <div className="contact-block">
                <Icon name="paper-plane" color={`${finalTextColour}80`} />
                <span
                  dangerouslySetInnerHTML={{
                    __html: formatEmailToLink(contactDetails.emails[0].email),
                  }}
                />
              </div>
            )}
            <div className="numbers">
              {contactDetails.telephoneNumbers &&
                contactDetails.telephoneNumbers.map(
                  ({ telephoneNumber, type }) => (
                    <div className="contact-block">
                      <Icon
                        name={type === "whatsapp" ? type : "mobile"}
                        color={`${finalTextColour}80`}
                      />
                      <span
                        dangerouslySetInnerHTML={{
                          __html: {
                            call: formatPhoneToLink,
                            whatsapp: formatPhoneToWhatsappLink,
                          }[type](telephoneNumber),
                        }}
                      />
                    </div>
                  )
                )}
            </div>
          </div>
          <div className="bottom-row">
            {footerContent.copyrightDefaultYear
              ? `© ${new Date().getFullYear()} `
              : null}
            <span
              dangerouslySetInnerHTML={{
                __html: footerContent.copyrightText,
              }}
            />
            <FooterMenu hoverColour={hoverColour}>
              {menu.menuItems.nodes.map((menuItem) => (
                <MenuLink key={menuItem.id} menuItem={menuItem} />
              ))}
            </FooterMenu>
          </div>
        </DetailStart>
        <DetailEnd>
          <SocialIcons color={finalTextColour} />
          {footerContent.showAccreditation && (
            <div className="accreditation">
              <InnerHTML html={footerContent.accreditationText} />
            </div>
          )}
        </DetailEnd>
      </Inner>
    </FooterWrapper>
  )
}
export default FooterLayoutA

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const FooterWrapper = styled.footer`
  padding: 20px 0;
  min-height: 60px;
  color: ${({ finalTextColour }) => finalTextColour};
  background: ${({ backgroundColour, lightSkin, theme }) =>
    backgroundColour
      ? backgroundColour
      : lightSkin
      ? theme.colors.midnight[100]
      : theme.colors.midnight[400]};
  a {
    color: ${({ finalTextColour }) => finalTextColour};
    &:hover {
      color: ${({ hoverColour }) => hoverColour};
    }
  }
  .inner {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    .inner {
      grid-template-columns: 1fr;
      justify-items: center;
      text-align: center;
      grid-gap: 20px;
    }
  }
`
const DetailStart = styled.div`
  font-family: ${({ bodyTypography }) =>
    getFontFamily(bodyTypography.fontFamily)};
  font-size: 0.85rem;
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-gap: 10px;
  .top-row {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 20px;
  }
  .contact-block {
    display: grid;
    align-items: center;
    grid-template-columns: 33px 1fr;
    a {
      text-decoration: none;
      transition: all 200ms ease-in-out;
      &:hover {
        opacity: 1;
      }
    }
  }
  .numbers {
    display: flex;
    gap: 20px;
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    .top-row {
      grid-template-columns: 1fr;
      text-align: left;
      justify-items: center;
      grid-gap: 2px;
    }
  }
`
const DetailEnd = styled.div`
  .social-icons {
    justify-content: flex-end;
  }
  .accreditation {
    p {
      font-size: 0.7rem;
      text-align: right;
      opacity: 0.6;
      transition: all 200ms ease-in-out;
      margin: 15px 0 0 0;
    }
    a {
      text-decoration: none;
    }
    &:hover {
      opacity: 1;
      a {
        border-bottom: 1px solid ${({ hoverColour }) => hoverColour};
      }
    }
  }
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    .accreditation {
      text-align: center;
      p {
        text-align: center;
      }
    }
    .social-icons {
      justify-content: center;
    }
  }
`
const FooterMenu = styled.span`
  padding-left: 10px;
  a {
    padding: 2px 3px;
    margin: 0 4px;
    text-decoration: none;
    border-bottom: 2px solid ${({ hoverColour }) => `${hoverColour}4D`};
    transition: all 200ms ease-in-out;
    &:hover {
      border-bottom: 2px solid ${({ hoverColour }) => hoverColour};
    }
  }
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    display: block;
    margin: 10px 0;
  }
`
