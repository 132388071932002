import { graphql, useStaticQuery } from "gatsby"

export default () => {
  const data = useStaticQuery(graphql`
    {
      wpgraphql {
        flexisiteOptionsBranding {
          optionsBranding {
            shape
            lightSkin
            linkColor
            secondaryColor
            primaryColor
            defaultLogo {
              id
              sourceUrl
              imageFile {
                childImageSharp {
                  fluid(quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            headingTypography {
              fontFamily
              h1 {
                fontSize
                fontWeight
                lineHeight
              }
              h2 {
                fontSize
                fontWeight
                lineHeight
              }
              h3 {
                fontSize
                fontWeight
                lineHeight
              }
              h4 {
                fontSize
                fontWeight
                lineHeight
              }
              h5 {
                fontSize
                fontWeight
                lineHeight
              }
              h6 {
                fontSize
                fontWeight
                lineHeight
              }
            }
            bodyTypography {
              fontSize
              fontFamily
              lineHeight
            }
          }
        }
      }
    }
  `)

  return data?.wpgraphql?.flexisiteOptionsBranding?.optionsBranding
}
