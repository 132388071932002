import React from "react"

const IconQuoteLeft = ({ fill }) => (
  <svg
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.125 9.5H15V7C15 5.63281 16.0938 4.5 17.5 4.5H17.8125C18.3203 4.5 18.75 4.10938 18.75 3.5625V1.6875C18.75 1.17969 18.3203 0.75 17.8125 0.75H17.5C14.0234 0.75 11.25 3.5625 11.25 7V16.375C11.25 17.4297 12.0703 18.25 13.125 18.25H18.125C19.1406 18.25 20 17.4297 20 16.375V11.375C20 10.3594 19.1406 9.5 18.125 9.5ZM6.875 9.5H3.75V7C3.75 5.63281 4.84375 4.5 6.25 4.5H6.5625C7.07031 4.5 7.5 4.10938 7.5 3.5625V1.6875C7.5 1.17969 7.07031 0.75 6.5625 0.75H6.25C2.77344 0.75 0 3.5625 0 7V16.375C0 17.4297 0.820312 18.25 1.875 18.25H6.875C7.89062 18.25 8.75 17.4297 8.75 16.375V11.375C8.75 10.3594 7.89062 9.5 6.875 9.5Z"
      fill={fill}
      id="icon"
    />
  </svg>
)

export default IconQuoteLeft
