import { useState, useEffect } from "react"
import breakpoints from "../dsm/breakpoints"

const breaks = {
  large: parseFloat(breakpoints.breakLarge.replace("px", "")),
  medium: parseFloat(breakpoints.breakMedium.replace("px", "")),
  small: parseFloat(breakpoints.breakSmall.replace("px", "")),
  tiny: parseFloat(breakpoints.breakTiny.replace("px", "")),
}

///////////////////////////////////////
const useDetectLarge = () => {
  const [isMobile, setMobile] = useState(false)
  useEffect(() => {
    window.addEventListener("resize", updateDimensions)
    updateDimensions()
  }, [])

  const updateDimensions = () => {
    if (window.innerWidth < breaks.large) {
      setMobile(true)
    } else {
      setMobile(false)
    }
  }
  return isMobile
}

///////////////////////////////////////
const useDetectMedium = () => {
  const [isMobile, setMobile] = useState(false)
  useEffect(() => {
    window.addEventListener("resize", updateDimensions)
    updateDimensions()
  }, [])
  const updateDimensions = () => {
    if (window.innerWidth < breaks.medium) {
      setMobile(true)
    } else {
      setMobile(false)
    }
  }
  return isMobile
}

///////////////////////////////////////
const useDetectSmall = () => {
  const [isMobile, setMobile] = useState(false)
  useEffect(() => {
    window.addEventListener("resize", updateDimensions)
    updateDimensions()
  }, [])

  const updateDimensions = () => {
    if (window.innerWidth < breaks.small) {
      setMobile(true)
    } else {
      setMobile(false)
    }
  }
  return isMobile
}
///////////////////////////////////////
const useDetectTiny = () => {
  const [isMobile, setMobile] = useState(false)
  useEffect(() => {
    window.addEventListener("resize", updateDimensions)
    updateDimensions()
  }, [])
  const updateDimensions = () => {
    if (window.innerWidth < breaks.tiny) {
      setMobile(true)
    } else {
      setMobile(false)
    }
  }
  return isMobile
}

export { useDetectLarge, useDetectMedium, useDetectSmall, useDetectTiny }
