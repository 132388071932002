import { graphql, useStaticQuery } from "gatsby"

export default () => {
  const data = useStaticQuery(graphql`
    {
      wpgraphql {
        allSettings {
          generalSettingsTitle
          readingSettingsPostsPerPage
        }
      }
    }
  `)

  return data.wpgraphql.allSettings
}
