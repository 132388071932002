import React from "react"

const IconFacebook = ({ fill }) => (
  <svg
    width="12"
    height="21"
    viewBox="0 0 12 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.8984 11.75L11.4453 8.15625H7.96875V5.8125C7.96875 4.79688 8.4375 3.85938 10 3.85938H11.6016V0.773438C11.6016 0.773438 10.1562 0.5 8.78906 0.5C5.9375 0.5 4.0625 2.25781 4.0625 5.38281V8.15625H0.859375V11.75H4.0625V20.5H7.96875V11.75H10.8984Z"
      fill={fill}
      id="icon"
    />
  </svg>
)

export default IconFacebook
