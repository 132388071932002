import React from "react"
import styled from "styled-components"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import "gatsby-plugin-breadcrumb/gatsby-plugin-breadcrumb.css"
import BackgroundImage from "gatsby-background-image"

import Inner from "../../../dsm/layout/Inner"
///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const PageHeaderLayoutA = ({
  title,
  crumbs,
  vSpacing,
  alignment,
  textColour,
  showBreadcrumbs,
  backgroundImage,
  colorHasPriority,
  backgroundColour,
  showGradientOverlay,
  isPreview,
}) => {
  // Determine if we need a wrapper for a background color or image
  let Wrapper = WrapperColor
  let wrapperProps = {}
  // if local page has a background colour override global image
  if (colorHasPriority) {
    wrapperProps = {
      backgroundColour: backgroundColour,
    }
  } else {
    Wrapper = WrapperImage
    wrapperProps = isPreview
      ? { background: `background: url(${isPreview});`, as: "div" }
      : {
          fluid: backgroundImage.imageFile.childImageSharp.fluid,
          backgroundColour: backgroundColour,
        }
  }

  return (
    <Wrapper {...wrapperProps}>
      <Section
        vSpacing={vSpacing}
        alignment={alignment}
        textColour={textColour}
        showGradientOverlay={showGradientOverlay}
      >
        <Inner>
          <h1>{title}</h1>
          {showBreadcrumbs && (
            <BreadcrumbWrapper textColour={textColour} alignment={alignment}>
              <Breadcrumb crumbs={crumbs} crumbLabel={title} />
            </BreadcrumbWrapper>
          )}
        </Inner>
      </Section>
    </Wrapper>
  )
}
export default PageHeaderLayoutA

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const WrapperImage = styled(BackgroundImage)`
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  background-color: ${({ backgroundColour }) => backgroundColour};
  ${({ background }) => background}
`
const WrapperColor = styled.div`
  background-color: ${({ backgroundColour }) => backgroundColour};
`
const Section = styled.section`
  padding: ${({ vSpacing }) => `${vSpacing} 0`};
  background: ${({ showGradientOverlay }) =>
    showGradientOverlay
      ? `linear-gradient(
    115deg,
    rgba(20, 35, 50, 0.60) 25%,
    rgba(25, 25, 50, 0) 75%
  )`
      : `none`};
  /* margin-top: -1px; */
  .inner {
    display: grid;
    justify-content: ${({ alignment }) => alignment};
  }
  color: ${({ textColour }) => textColour};
  h1 {
    color: ${({ textColour }) => textColour};
    margin: 0;
    text-align: ${({ alignment }) => alignment};
  }
`
const BreadcrumbWrapper = styled.div`
  nav {
    margin-top: 10px;
    &.breadcrumb {
      font-size: 1rem;
    }
    .breadcrumb__list {
      justify-content: ${({ alignment }) => alignment};
    }
    .breadcrumb__list__item {
      margin: 0 10px 0 0;
      text-transform: capitalize;
      a {
        color: ${({ textColour }) => `${textColour}99`};
        border-bottom: 1px dotted ${({ textColour }) => `${textColour}66`};
        transition: all 200ms ease-in-out;
        &:hover {
          color: ${({ textColour }) => textColour};
          border-bottom: 1px solid ${({ textColour }) => textColour};
        }
      }
    }
    .breadcrumb__separator {
      color: ${({ textColour }) => `${textColour}66`};
      margin: 0 10px 0 0;
    }
  }
`
