import React from "react"

const IconChevronDown = ({ fill }) => (
  <svg
    width="18"
    height="11"
    viewBox="0 0 18 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.4766 1.0625L16.7344 0.28125C16.5391 0.0859375 16.2266 0.0859375 16.0703 0.28125L9 7.35156L1.89062 0.28125C1.73438 0.0859375 1.42188 0.0859375 1.22656 0.28125L0.484375 1.0625C0.289062 1.21875 0.289062 1.53125 0.484375 1.72656L8.64844 9.89062C8.84375 10.0859 9.11719 10.0859 9.3125 9.89062L17.4766 1.72656C17.6719 1.53125 17.6719 1.21875 17.4766 1.0625Z"
      fill={fill}
      id="icon"
    />
  </svg>
)

export default IconChevronDown
