const config = {
  apiKey: "AIzaSyATg4GH3-lRsZn4AIkZFbqdH3dnb7bIe4s",
  authDomain: "flexisite-prod.firebaseapp.com",
  databaseURL: "https://flexisite-prod.firebaseio.com",
  projectId: "flexisite-prod",
  storageBucket: "flexisite-prod.appspot.com",
  messagingSenderId: "745953316597",
  appId: "1:745953316597:web:30ba2860c67e0005d17fbc",
  measurementId: "G-BD9XL7LVN8",
}

// Initialize Firebase
let firebaseInstance
export const getFirebase = (firebase) => {
  if (firebaseInstance) {
    return firebaseInstance
  }

  firebase.initializeApp(config)
  firebaseInstance = firebase

  return firebase
}

export default getFirebase
