import { graphql, useStaticQuery } from "gatsby"

export default () => {
  const data = useStaticQuery(graphql`
    {
      wpgraphql {
        flexisiteOptionsGenericContent {
          optionsContent {
            contactDetails {
              address
              telephoneNumbers {
                telephoneNumber
                type
              }
              emails {
                email
              }
            }
            socialNetworks {
              name
              link
            }
            siteWideNotice {
              text
              position
              isActive
              cta {
                link
                label
                isExternal
                fieldGroupName
              }
            }
            footerContent {
              copyrightText
              showAccreditation
              accreditationText
              copyrightDefaultYear
            }
            cookiesNotice {
              message
              position
              isVisible
              buttonLabel
            }
            blogArchivePage {
              introText
              pageHeader
            }
            itemsArchivePage {
              introText
              pageHeader
            }
          }
        }
      }
    }
  `)

  return data?.wpgraphql?.flexisiteOptionsGenericContent?.optionsContent
}
