import React from "react"

import getFlexiOptionsLayouts from "../../options/getFlexiOptionsLayouts"
import PageHeaderLayoutA from "./layouts/LayoutA"
import PageHeaderLayoutB from "./layouts/LayoutB"
import PageHeaderLayoutC from "./layouts/LayoutC"
import { getVerticalSpacing } from "../../utils"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const PageHeader = ({
  title,
  crumbs,
  layout,
  buttons,
  alignment,
  introText,
  textColour,
  backgroundImage,
  verticalSpacing,
  backgroundColour,
  showGradientOverlay,
}) => {
  const { pageHeaderConfig } = getFlexiOptionsLayouts()
  const isPreview = backgroundImage?.mediaItemUrl
  // Does this page override layout? Fallback to Global Setting
  const FinalLayout = layout || pageHeaderConfig.layout
  const FinalAlignment = alignment || pageHeaderConfig.alignment
  const FinalVSpacing = verticalSpacing || pageHeaderConfig.verticalSpacing
  const FinalBackgroundColour =
    backgroundColour || pageHeaderConfig.backgroundColour
  const FinalTextColour = textColour || pageHeaderConfig.textColour
  const FinalBgImg = backgroundImage || pageHeaderConfig.backgroundImage
  const FinalShowGradient =
    showGradientOverlay || pageHeaderConfig.showGradientOverlay

  const colorHasPriority = !FinalBgImg

  let Layout = PageHeaderLayoutA
  switch (FinalLayout) {
    case "a":
      Layout = PageHeaderLayoutA
      break
    case "b":
      Layout = PageHeaderLayoutB
      break
    case "c":
      Layout = PageHeaderLayoutC
      break
    default:
      Layout = PageHeaderLayoutA
      break
  }
  return (
    <Layout
      title={title}
      buttons={buttons}
      isPreview={isPreview}
      introText={introText}
      alignment={FinalAlignment}
      vSpacing={getVerticalSpacing(FinalVSpacing)}
      textColour={FinalTextColour}
      backgroundColour={FinalBackgroundColour}
      backgroundImage={FinalBgImg}
      colorHasPriority={colorHasPriority}
      pageHeaderConfig={pageHeaderConfig}
      showGradientOverlay={FinalShowGradient}
      showBreadcrumbs={pageHeaderConfig.showBreadcrumbs}
      crumbs={crumbs}
    />
  )
}
export default PageHeader
