import React from "react"

const IconArrowRight = ({ fill }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.6875 0.146484L8.41406 0.419922C8.21875 0.615234 8.21875 0.888672 8.41406 1.08398L15.3672 8.03711H0.71875C0.445312 8.03711 0.25 8.27148 0.25 8.50586V8.89648C0.25 9.16992 0.445312 9.36523 0.71875 9.36523H15.3672L8.41406 16.3574C8.21875 16.5527 8.21875 16.8262 8.41406 17.0215L8.6875 17.2949C8.88281 17.4902 9.15625 17.4902 9.35156 17.2949L17.5938 9.05273C17.7891 8.85742 17.7891 8.58398 17.5938 8.38867L9.35156 0.146484C9.15625 -0.0488281 8.88281 -0.0488281 8.6875 0.146484Z"
      fill={fill}
      id="icon"
    />
  </svg>
)

export default IconArrowRight
