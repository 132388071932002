import React from "react"

const IconWhatsapp = ({ fill }) => (
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.0938 3.32812C13.4922 1.6875 11.3047 0.75 8.96094 0.75C4.19531 0.75 0.289062 4.65625 0.289062 9.42188C0.289062 10.9844 0.71875 12.4688 1.46094 13.7578L0.25 18.25L4.82031 17.0781C6.10938 17.7422 7.51562 18.1328 8.96094 18.1328C13.7656 18.1328 17.75 14.2266 17.75 9.46094C17.75 7.11719 16.7344 4.96875 15.0938 3.32812ZM8.96094 16.6484C7.67188 16.6484 6.42188 16.2969 5.28906 15.6328L5.05469 15.4766L2.32031 16.2188L3.0625 13.5625L2.86719 13.2891C2.16406 12.1172 1.77344 10.7891 1.77344 9.42188C1.77344 5.47656 5.01562 2.23438 9 2.23438C10.9141 2.23438 12.7109 2.97656 14.0781 4.34375C15.4453 5.71094 16.2656 7.50781 16.2656 9.46094C16.2656 13.4062 12.9453 16.6484 8.96094 16.6484ZM12.9453 11.2578C12.7109 11.1406 11.6562 10.6328 11.4609 10.5547C11.2656 10.4766 11.1094 10.4375 10.9531 10.6719C10.8359 10.8672 10.4062 11.375 10.2891 11.5312C10.1328 11.6484 10.0156 11.6875 9.82031 11.5703C8.53125 10.9453 7.71094 10.4375 6.85156 8.99219C6.61719 8.60156 7.08594 8.64062 7.47656 7.82031C7.55469 7.66406 7.51562 7.54688 7.47656 7.42969C7.4375 7.3125 6.96875 6.25781 6.8125 5.82812C6.61719 5.39844 6.46094 5.4375 6.30469 5.4375C6.1875 5.4375 6.03125 5.4375 5.91406 5.4375C5.75781 5.4375 5.52344 5.47656 5.32812 5.71094C5.13281 5.94531 4.58594 6.45312 4.58594 7.50781C4.58594 8.60156 5.32812 9.61719 5.44531 9.77344C5.5625 9.89062 6.96875 12.0781 9.15625 13.0156C10.5234 13.6406 11.0703 13.6797 11.7734 13.5625C12.1641 13.5234 13.0234 13.0547 13.2188 12.5469C13.4141 12.0391 13.4141 11.6094 13.3359 11.5312C13.2969 11.4141 13.1406 11.375 12.9453 11.2578Z"
      fill={fill}
    />
  </svg>
)

export default IconWhatsapp
