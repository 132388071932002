import gql from "graphql-tag"
import { useQuery } from "@apollo/react-hooks"

export default () => {
  const { loading, data } = useQuery(gql`
    {
      flexisiteOptionsAdmin {
        optionsAdmin {
          packageType
        }
      }
    }
  `)

  return !loading && data?.flexisiteOptionsAdmin?.optionsAdmin
}
