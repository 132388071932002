import React, { useEffect, createContext, useState, useContext } from "react"
import getFirebase from "./firebase"
import getWPSettings from "../../options/getWPSettings"

const FirebaseContext = createContext()

const FirebaseProvider = ({ children }) => {
  const [instance, setInstance] = useState(null)

  useEffect(() => {
    const app = import("firebase/app")
    const db = import("firebase/firestore")
    const storage = import("firebase/storage")

    Promise.all([app, db, storage]).then(([firebase]) => {
      const database = getFirebase(firebase)
      setInstance(database)
    })
  }, [])

  const { generalSettingsTitle } = getWPSettings()

  const createEnquiry = ({
    fields = [],
    siteName = generalSettingsTitle,
    formName = "",
    triggerNotification = null,
    toEmails = "",
  }) => {
    const dateCreated = new Date()
    return instance.firestore().collection("enquiries").add({
      fields,
      siteName,
      formName,
      toEmails,
      dateCreated,
      triggerNotification,
    })
  }

  return (
    <FirebaseContext.Provider
      value={{
        firebase: instance,
        createEnquiry,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  )
}

const useFirebase = () => useContext(FirebaseContext)

export { useFirebase }
export default FirebaseProvider
