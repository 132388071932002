import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import colors from "../colors"
// A
import IconArrowRight from "./files/arrow-right"
import IconArrowLeft from "./files/arrow-left"
// B
// C
import IconCalendar from "./files/calendar"
import IconCheck from "./files/check"
import IconChevronDown from "./files/chevron-down"
// D
import IconDribbble from "./files/dribbble"
// E
import IconEnvelope from "./files/envelope"
import IconExclamation from "./files/exclamation"
// F
import IconFacebook from "./files/facebook"
// G
// H
// I
import IconInstagram from "./files/instagram"
import IconInfo from "./files/info"
// J
// K
// L
import IconLinkedIn from "./files/linkedin"
// M
import IconMapMarked from "./files/map-marked"
import IconMobile from "./files/mobile"
// N
// O
// P
import IconPaperPlane from "./files/paper-plane"
import IconPinterest from "./files/pinterest"
// Q
import IconQuoteLeft from "./files/quote-left"
// R
// S
import IconSoundCloud from "./files/soundcloud"
import IconSpotify from "./files/spotify"
// T
import IconTimes from "./files/times"
import IconTwitter from "./files/twitter"
// U
// V
import IconVimeo from "./files/vimeo"
// W
import IconWhatsapp from "./files/whatsapp"
// X
// Y
import IconYouTube from "./files/youtube"
// Z

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const Icon = ({ name, hue, shade, size, className, onClick, color }) => {
  // Get Fill
  let fill = color || colors[hue][shade]
  // Get Size
  let dimensions = ""
  let scale = ""
  switch (size) {
    case "sm":
      dimensions = "24px"
      scale = "1"
      break
    case "md":
      dimensions = "32px"
      scale = "1.3"
      break
    case "lg":
      dimensions = "45px"
      scale = "2"
      break
    case "xl":
      dimensions = "60px"
      scale = "3"
      break
    default:
      dimensions = "24px"
      scale = "1"
  }

  return (
    <IconContainer
      className={`icon ${className ? className : name}`}
      onClick={onClick}
    >
      <IconWrapper dimensions={dimensions} scale={scale}>
        {/* A */}
        {name === "arrow-right" && <IconArrowRight fill={fill} />}
        {name === "arrow-left" && <IconArrowLeft fill={fill} />}
        {/* B */}
        {/* C */}
        {name === "calendar" && <IconCalendar fill={fill} />}
        {name === "check" && <IconCheck fill={fill} />}
        {name === "chevron-down" && <IconChevronDown fill={fill} />}
        {/* D */}
        {name === "dribbble" && <IconDribbble fill={fill} />}
        {/* E */}
        {name === "envelope" && <IconEnvelope fill={fill} />}
        {name === "exclamation" && <IconExclamation fill={fill} />}
        {/* F */}
        {name === "facebook" && <IconFacebook fill={fill} />}
        {/* G */}
        {/* H */}
        {/* I */}
        {name === "info" && <IconInfo fill={fill} />}
        {name === "instagram" && <IconInstagram fill={fill} />}
        {/* J */}
        {/* K */}
        {/* L */}
        {name === "linkedin" && <IconLinkedIn fill={fill} />}
        {/* M */}
        {name === "mobile" && <IconMobile fill={fill} />}
        {name === "map-marked" && <IconMapMarked fill={fill} />}
        {/* N */}
        {/* O */}
        {/* P */}
        {name === "paper-plane" && <IconPaperPlane fill={fill} />}
        {name === "pinterest" && <IconPinterest fill={fill} />}
        {/* Q */}
        {name === "quote-left" && <IconQuoteLeft fill={fill} />}
        {/* R */}
        {/* S */}
        {name === "spotify" && <IconSpotify fill={fill} />}
        {name === "soundcloud" && <IconSoundCloud fill={fill} />}
        {/* T */}
        {name === "times" && <IconTimes fill={fill} />}
        {name === "twitter" && <IconTwitter fill={fill} />}
        {/* U */}
        {/* V */}
        {name === "vimeo" && <IconVimeo fill={fill} />}
        {/* W */}
        {name === "whatsapp" && <IconWhatsapp fill={fill} />}
        {/* X */}
        {/* Y */}
        {name === "youtube" && <IconYouTube fill={fill} />}
        {/* Z */}
      </IconWrapper>
    </IconContainer>
  )
}

Icon.propTypes = {
  name: PropTypes.string,
  hue: PropTypes.string,
  shade: PropTypes.string,
  size: PropTypes.string,
}
Icon.defaultProps = {
  name: "paper-plane",
  hue: "midnight",
  shade: "300",
  size: "sm",
}

export default Icon

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const IconContainer = styled.span`
  display: inline-block;
`
const IconWrapper = styled.span`
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  align-items: center;
  width: ${({ dimensions }) => dimensions};
  height: ${({ dimensions }) => dimensions};
  svg {
    transform: scale(${({ scale }) => scale}, ${({ scale }) => scale});
    #icon {
      transition: all 200ms ease-in-out;
    }
  }
`
