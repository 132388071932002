import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import FooterLayoutA from "./layouts/LayoutA"
import FooterLayoutB from "./layouts/LayoutB"
import { getAssetColours } from "../../utils"
import getFlexiOptionsContent from "../../options/getFlexiOptionsContent"
import getFlexiOptionsLayouts from "../../options/getFlexiOptionsLayouts"
import getFlexiOptionsBranding from "../../options/getFlexiOptionsBranding"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const Footer = () => {
  const { menu } = useStaticQuery(FOOTER_QUERY)

  const { footerConfig } = getFlexiOptionsLayouts()
  const { bodyTypography, lightSkin } = getFlexiOptionsBranding()
  const { contactDetails, footerContent } = getFlexiOptionsContent()

  let Layout =
    { a: FooterLayoutA, b: FooterLayoutB }[footerConfig.layout] || FooterLayoutA

  return (
    <Layout
      menu={menu}
      lightSkin={lightSkin}
      footerConfig={footerConfig}
      footerContent={footerContent}
      contactDetails={contactDetails}
      bodyTypography={bodyTypography}
      textColour={footerConfig.textColour}
      backgroundColour={footerConfig.backgroundColour}
      hoverColour={getAssetColours(footerConfig.hoverColour)}
    />
  )
}
export default Footer

///////////////////////////////////////
// 🔎 Queries
///////////////////////////////////////
const FOOTER_QUERY = graphql`
  {
    menu: wpgraphql {
      menuItems(where: { location: FOOTER }) {
        nodes {
          ...MenuFields
        }
      }
    }
  }
`
