import React, { useState } from "react"
import { Link } from "gatsby"
import { CreateLocalLink } from "../../utils"
import styled from "styled-components"

const MenuLink = ({ menuItem, stop = false }) => {
  const [open, setOpen] = useState(false)

  var sub_items = menuItem?.childItems?.nodes

  return (
    <Wrap
      {...(sub_items?.length
        ? {
            onMouseEnter: () => setOpen(true),
            onMouseLeave: () => setOpen(false),
          }
        : {})}
    >
      <NavLink {...menuItem} />
      {sub_items?.length && !stop ? (
        <Sub className="sub-items" open={open}>
          {sub_items.map((i) => (
            <NavLink key={i.id} {...i} />
          ))}
        </Sub>
      ) : null}
    </Wrap>
  )
}

const NavLink = (i) => (
  <Link {...renderLink(i)}>
    <span dangerouslySetInnerHTML={{ __html: i.label }} />
  </Link>
)

/**
 * renders local or external link based off props
 * @param {menuItem} menuItem
 */
function renderLink(menuItem) {
  if (menuItem.target === "_blank") {
    return {
      as: "a",
      href: menuItem.url,
      rel: "noopener noreferrer",
      target: "_blank",
      className: menuItem.cssClasses.join(" "),
      label: menuItem.label,
    }
  } else if (CreateLocalLink(menuItem.url)) {
    return {
      // as: Link,
      to: CreateLocalLink(menuItem.url),
      className: menuItem.cssClasses.join(" "),
      label: menuItem.label,
    }
  }
}

const Sub = styled.div`
  position: absolute;
  transition: 0.2s all ease-in-out;
  overflow: hidden;
  height: ${({ open }) => (open ? "auto" : 0)};
  z-index: 1;
  background: ${({ theme, lightSkin }) =>
    lightSkin ? theme.colors.midnight[100] : theme.colors.white[100]};
  a {
    display: block !important;
  }
`
const Wrap = styled.div`
  display: inline-block;
  position: relative;
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    display: block;
  }
`

export default MenuLink
